import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./dashboard.module.scss";
import sponser from "../../../assets/images/dashboard/sponcer.png";
import TileItems from "./TileItems";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import tilesLoader from "../../../assets/images/loading.gif";
import eye from "../../components/dashboard/view.png";
import { Table, Card } from "react-bootstrap";
import wallet from "../../../assets/images/dashboard/CWAR I.png";
import { OverlayTrigger, Popover, Button, Tooltip } from "react-bootstrap";
import { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { blue } from "@mui/material/colors";
import carbonCerti from "../../../assets/images/dashboard/wallet-icon(1).png";
import { CurrencyDecimalFormat } from "../../helper";
import { formLabelClasses } from "@mui/material";
import { useEffect } from "react";
import NonFilteredTile from "./NonFilteredTile";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Spinner } from "react-bootstrap";
import NFTService from "../../../service/NFT/nft";
import DashboardService from "../../../service/dashboard/Dashboard";
import carbonIcon from "../../../assets/images/cwaricon.png";
function Tile(props) {
  console.log("🚀vimal", props);
  const { t } = useTranslation();
  const { currentTheme } = useSelector((state) => state.Theme);
  const [yourwalletEye, seteye] = useState(false);
  const [stakeEye, setstakeEye] = useState(false);
  const [commissionEye, setcommissionEye] = useState(false);
  const [vestedEye, setvestedEye] = useState(false);
  const [totalEye, settotalEye] = useState(false);
  const [commissionLoder, setcommissionLoder] = useState(false);
  const [nonFilteredArrays, setnonFilteredArrays] = useState([]);

  const [nftInfo, setnftInfo] = useState(props?.nftInfo);

  const [updatedCommission, setupdatedCommission] = useState({
    data: [],
    status: false,
  });

  const eyeHandler = () => {
    seteye(!yourwalletEye);
  };
  const stakeEyehandler = () => {
    setstakeEye(!stakeEye);
  };
  const commissionEyehandler = () => {
    setcommissionEye(!commissionEye);
  };
  const vestedEyehandler = () => {
    setvestedEye(!vestedEye);
  };
  const totalEyeHanler = () => {
    settotalEye(!totalEye);
  };

  useEffect(() => {
    if (props?.Tiles?.length > 0) {
      const FiletredData = props?.Tiles?.filter((obj) => obj.filter === false);
      setnonFilteredArrays(FiletredData);
    } else {
      return;
    }
  }, [props.Tiles]);

  console.log("tiltesdatta", nonFilteredArrays);

  const carboninfomation = <Tooltip>{t("Common.info")}</Tooltip>;

  const getCommissionValueHandler = () => {
    setcommissionLoder(true);
    const service = new DashboardService();
    const updateSts = true;
    service.dashboardTiles(updateSts).then((res) => {
      if (res.status) {
        if (res.data) {
          setcommissionLoder(false);

          setupdatedCommission((prev) => ({
            ...prev,
            data: res.data,
            status: true,
          }));
        }
      } else {
        setcommissionLoder(true);
      }
    });
  };

  return (
    <div className={style.dashboard_boxs_listing_sponcer_sec}>
      <Row className="fll_wdth_row_tab">
        <Col md={12} className={style.mid_flll_wdth}>
          {props.Tiles && props.Tiles.length > 0 && (
            <>
              <div className={style.boxes_lst_sec}>
                {props.nftBalanceTile
                  .filter((obj) => obj.filter === true)
                  .map((items, index) => {
                    console.log("tilesssssfbgffg", items);

                    return (
                      <TileItems
                        amount={items.amount}
                        // accumulatedRewards={500}
                        // totalRewards={2000}
                        accumulatedRewards={items?.accumulatedRewards}
                        totalRewards={items?.totalRewards}
                        AnnualRewards={items.AnnualRewards}
                        text={items.text}
                        to={items.to}
                        key={index}
                        boxname={index}
                        filter={items.filter}
                        icon={items.icon}
                        filterChange={props.filterChange}
                        lodervalue={props.lodervalue}
                        FiletredData={nonFilteredArrays}
                        amount_withcurrency={items.withcurrency}
                      />
                    );
                  })}
              </div>
            </>
          )}
        </Col>
        <Col md={12} className={style.mid_flll_wdth}>
          {props.Tiles && props.Tiles.length > 0 && (
            <>
              <div className={style.boxes_lst_sec}>
                {props.Tiles.filter((obj) => obj.filter === false).map(
                  (items, index) => {
                    console.log("tilesssssfbgffg", items);

                    return (
                      <TileItems
                        amount={items.amount}
                        // accumulatedRewards={500}
                        // totalRewards={2000}
                        accumulatedRewards={items?.accumulatedRewards}
                        totalRewards={items?.totalRewards}
                        AnnualRewards={items.AnnualRewards}
                        text={items.text}
                        to={items.to}
                        key={index}
                        boxname={index}
                        filter={items.filter}
                        icon={items.icon}
                        filterChange={props.filterChange}
                        lodervalue={props.lodervalue}
                        FiletredData={nonFilteredArrays}
                        amount_withcurrency={items.withcurrency}
                      />
                    );
                  }
                )}
              </div>
            </>
          )}
        </Col>

        <Col
          md={12}
          className={style.mid_flll_wdth}
          style={{ marginTop: "14px" }}
        >
          {props.SponserData && (
            <div className={`${style.sponcer_sec}`}>
              <div
                className={`${
                  currentTheme == "theme-dark"
                    ? `${style.dark_mode_dashboard_pannel_box}`
                    : `${style.dashboard_pannel_box}`
                }`}
              >
                <>
                  {props.SponserData[0].text === "sponsorName" && (
                    <div
                      className={`${
                        currentTheme == "theme-dark"
                          ? `${style.dark_mode_sponcer_top_name_sec}`
                          : `${style.sponcer_top_name_sec}`
                      }`}
                    >
                      <div
                        className={`${
                          currentTheme == "theme-dark"
                            ? `${style.dark_mode_sponcer_top_name_txt}`
                            : `${style.sponcer_top_name_txt}`
                        }`}
                      >
                        <span>{t("Common.sponsor")}</span>
                        {props.SponserData[0].head}
                      </div>

                      <div className={style.sponcer_top_ico}>
                        <img
                          src={sponser}
                          alt=""
                          style={{
                            mixBlendMode:
                              currentTheme == "theme-dark" ? "multiply" : "",
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className={style.spouncer_cnt_row}>
                    {props.pvData.map((data, index) => {
                      console.log("sponsorr", data);
                      return (
                        <div
                          className={`${
                            currentTheme == "theme-dark"
                              ? `${style.dark_mode_spouncer_cnt_boxes}`
                              : `${style.spouncer_cnt_boxes}`
                          }`}
                          key={index}
                        >
                          {t("Common." + data.text)}
                          {props.lodervalue ? (
                            <strong>
                              <img src={tilesLoader} width="40px" />
                            </strong>
                          ) : (
                            <strong>{data.head.toLocaleString("en-US")}</strong>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              </div>
            </div>
          )}
        </Col>
        <Col md={12} sm={12} className={style.carbonTablecontainer}>
          <Card style={{ display: "inline-block", width: "100%" }}>
            <Card.Body>
              <Card.Title>
                <h4
                  style={{
                    color: currentTheme == "theme-dark" ? "white" : "black",
                  }}
                >
                  {t("Common.carboncertificateInformation")}
                </h4>
              </Card.Title>

              <Table className={style.carbonTable} responsive>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      {t("Common.urwallet")}
                      <i
                        style={{
                          color: yourwalletEye ? "green" : "#DF3B3B",
                          fontSize: "18px",
                        }}
                        className={
                          yourwalletEye ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                        aria-hidden="true"
                        onClick={eyeHandler}
                      ></i>
                    </th>

                    <th>
                      {/* <span>StakedEarnings<br/>(non-vested)</span> */}
                      {t("Common.stakedEarnings")}
                      <br />({t("Common.nonVested")})
                      <i
                        style={{
                          color: stakeEye ? "green" : "#DF3B3B",
                          fontSize: "18px",
                        }}
                        className={stakeEye ? "fa fa-eye" : "fa fa-eye-slash"}
                        aria-hidden="true"
                        onClick={stakeEyehandler}
                      ></i>
                    </th>
                    {props.affiliate_status && (
                      <th>
                        {t("Common.commission")}
                        {commissionLoder ? (
                          <span>
                            <Spinner animation="border" size="sm" />
                          </span>
                        ) : (
                          <span onClick={getCommissionValueHandler}>
                            <RefreshIcon />
                          </span>
                        )}
                        <br />({t("Common.nonVested")})
                        <i
                          style={{
                            color: commissionEye ? "green" : "#DF3B3B",
                            fontSize: "18px",
                          }}
                          className={
                            commissionEye ? "fa fa-eye" : "fa fa-eye-slash"
                          }
                          aria-hidden="true"
                          onClick={commissionEyehandler}
                        ></i>
                      </th>
                    )}
                  </tr>
                </thead>

                <tbody className="carbon-Tablebody">
                  {props.lodervalue ? (
                    <center>
                      <strong>
                        <img src={tilesLoader} width="40px" />
                      </strong>
                    </center>
                  ) : (
                    <>
                      {props?.nftInfo?.map((item, index) => {
                        console.log("itemsss", item);
                        return (
                          <tr>
                            {item.status ? (
                              <td>
                                <img
                                  src={carbonCerti}
                                  style={{
                                    background: "red",
                                    padding: "2px",
                                    objectFit: "contain",
                                  }}
                                />
                                <OverlayTrigger
                                  placement="top"
                                  overlay={carboninfomation}
                                >
                                  <InfoIcon
                                    fontSize="small"
                                    sx={{ color: blue[500] }}
                                    style={{
                                      marginTop: "-1em",
                                      marginRight: "-1em",
                                      cursor: "pointer",
                                      position: "relative",
                                      left: "-12px",
                                    }}
                                  />
                                </OverlayTrigger>
                              </td>
                            ) : (
                              <td>
                                <img src={carbonIcon} />{" "}
                              </td>
                            )}

                            {yourwalletEye ? (
                              <td>
                                {props?.your_wallet[index]
                                  .toFixed(3)
                                  .toLocaleString("en-US")}
                              </td>
                            ) : (
                              <td>****</td>
                            )}
                            {stakeEye ? (
                              <td>
                                {item.stakedEarnings
                                  .toFixed(8)
                                  .toLocaleString("en-US")}
                              </td>
                            ) : (
                              <td>****</td>
                            )}
                            {props.affiliate_status && (
                              <>
                                {commissionLoder ? (
                                  <td>
                                    <Spinner animation="border" size="sm" />
                                  </td>
                                ) : commissionEye ? (
                                  <td>
                                    {item.commission
                                      .toFixed(8)
                                      .toLocaleString("en-US")}
                                  </td>
                                ) : (
                                  <td>****</td>
                                )}
                              </>
                            )}
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Tile;
