import React, { useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal";
import style from "./registration.module.scss";
import { useHistory } from "react-router-dom";
import { Row, Col, Alert, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ReplicaRegService from "../../../service/Auth/Replicareg";
import VerificationForm from "../dashboard/VerificationForm";
import Mailotp from "./Mailotp";
import modalstyle from '../../components/crm/crm.module.scss'
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import {
  SponsorPackage,
  ContactInformation,
  LoginInformation,
  TAndC,
  Payment,
} from "./";
import RegisterService from "../../../service/Auth/Register";

import ProfileService from "../../../service/profile";
import { PasswordValidation } from "../../hook/passwordValidation";
import { useParams } from "react-router-dom";
import { ConstructionOutlined } from "@mui/icons-material";

function Content(props) {
  const { t } = useTranslation();
  let history = useHistory();
  let params = useParams();
  const [password, setPassword] = useState({
    password: "",
    policy: "",
  });
  const [validLength, hasNumber, upperCase, lowerCase, specialChar, mixedCase] = PasswordValidation({
    password: password.password,
    policy: password.policy
  });
  const [loading, setLoading] = useState(false)
  //service class object
  const servcie = new RegisterService();
  const [termsAndCond, setTermsAndCond] = useState({
    show: false,
    content: "",
  });

  //wizard
  const [page, setPage] = useState(0);
  const [steps, setSteps] = useState([
    { title: "" },
    { title: "" },
    { title: "" },
  ]);

  //current payment method
  const [payment, setPayment] = useState(
    props.data.paymentMethods.fields[0]?.code
  );

  const [otpModals, setotpModal] = useState({
    show: false,
  });

  const [MailotpModals, setmailotpModal] = useState({
    show: false,
  });

  useEffect(() => {
    if (props.data.PaymentMethodsStatus) {
      setSteps([{ title: "" }, { title: "" }, { title: "" }, { title: "" }]);
    }
  }, [props]);

  //wizard pages
  const nextPage = () => {
    // e.preventDefault()
    setPage(page + 1);
  };

  //ewallet data
  const [ewalletData, setEwalletData] = useState({
    username: "",
    password: "",
  });
  //use state create the data
  const [state, setState] = useState({
    sponsor: props.data.sponsor.fields,
    contactInfo: props.data.contactInfo.fields,
    LoginInfo: props.data.loginInformation.fields,
    PaymentMethods: props.data.paymentMethods,
    customFields: [],
    amount: 0,
    userName: "",
    product: "",
    userType: '',
    submitError: false,
    errorMessage: "",
    paypal: {
      orderId: '',
      payeerId: ''
    }
  })
  const [selection, setSelection] = useState()
  const [passwordPolicy, setPasswordPolicy] = useState({
    uppercase: upperCase,
    lowercase: lowerCase,
    sp_char: specialChar,
    number: hasNumber,
    min_length: validLength,
    mixedcase: mixedCase
  })
  useEffect(() => {
    setPasswordPolicy({
      uppercase: upperCase,
      lowercase: lowerCase,
      sp_char: specialChar,
      number: hasNumber,
      min_length: validLength,
      mixedcase: mixedCase
    })
  }, [validLength, hasNumber, upperCase, lowerCase, specialChar, mixedCase])

  //preve button click
  const prevPage = () => {
    setPage(page - 1);
  };


  const valiadtePhoneNumber = (phoneNumber) => {
    console.log("phonenumber", phoneNumber)

    // if(phoneNumber.phone_code){
    // Number = "+" + phoneNumber.phone_code + " " + phoneNumber.value;

    // }else{
    //  Number = "+" + phoneNumber.country_code + " " + phoneNumber.value;
    // }
    let Number = "+" + phoneNumber.phone_code + " " + phoneNumber.value;
    console.log("Number", Number)

    let valid = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      valid = phoneUtil.isValidNumber(phoneUtil.parse(Number));
    } catch (e) {
      valid = false;
    }
    if (valid) {
      return true;
    } else {
      return false;
    }
  };
  //change fields
  const ChangeHandler = (e, key) => {
    const { name, value } = e.target;
    console.log("nameee", name)
    const newData = state[key].map((items) => {
      if (items.code === name) {
        if (name === 'country') {
          const countryService = new ReplicaRegService();
          countryService.countryChange(value).then((res) => {
            console.log("countrcodee", res)
            if (res.status) {
              const newOptions = state.contactInfo.map((contactItems) => {
                if (contactItems.code == "state") {

                  return {
                    ...contactItems,
                    options: res.data,
                  }
                } if (contactItems.code === "mobile") {
                  return {
                    ...contactItems,
                    country_code: res.data.code,
                    country: res.data.country,
                    phone_codes: res.data.phone_codes
                  };
                }
                if (contactItems.code == "phone_validation") {
                  return {
                    ...contactItems,
                    value: false,
                    error: "",
                    errorField: {
                      field: "country",
                    },
                  };
                }
                if (contactItems.code == "country") {
                  return {
                    ...contactItems,
                    value: value
                  }
                }
                return contactItems
              })

              setState(prev => ({
                ...prev,
                contactInfo: newOptions
              }))

              setState((prev) => ({
                ...prev,
                contactInfo: newOptions,
              }));
            } else {
              if (res.error.code === 1002) {
                history.push("/logout");
              }
            }
          })
        } else if (items.code === 'password') {
          setPassword({
            ...password,
            password: value,
            policy: items.validation,
          });
        }

        else if (items.code === 'usertype') {
          console.log("entred====")
          setState((prev) => ({
            ...prev,
            userType: value
          }))
        }
        else if (items.code === "agree_terms") {
          let tempvalue = value === "false" ? false : true;
          return {
            ...items,
            value: !tempvalue,
            error: "",
            errorField: {
              field: "",
            },
          };
        }
        else if (items.code === "become_affiliate") {
          // console.log('affiliate',value)
          let tempvalue = value === "false" ? false : true;
          // if(value==false){
          //   return{
          //     ...items,
          //     value:value,
          //     error:"please accept the terms and conditions"
          //   }
          // }
          return {
            ...items,
            value: !tempvalue,
            error: "",
            errorField: {
              field: "",
            },
          };
        }
        return {
          ...items,
          value: value,
          error: "",
          errorField: {
            field: "",
          },
        };
      } else return items;
    });


    // if (name === 'usertype' && value === 'customer') {
    //   const index = newData.findIndex((item) => item.code === 'become_affiliate');
    //   if (index !== -1) {
    //     newData.splice(index, 1);
    //   }
    // } else if (name === 'usertype' && value === 'affiliate') {
    //   const index = newData.findIndex((item) => item.code === 'become_affiliate');
    //   if (index === -1) {
    //     newData.push({
    //       "code": "become_affiliate",
    //       "title": "I_ACCEPT_TO_BECOME_AN_AFFILIATE",
    //       "required": false,
    //       "isEditable": true,
    //       "type": "checkbox",
    //       "content": "All subscribers of SOFTWARE NAME services agree to be bound by the terms of this service. The SOFTWARE NAME software is an entire solution for all type of business plan like Binary, Matrix, Unilevel and many other compensation plans. This is developed by a leading MLM software development company COMPANY NAME. More over these we are keen to construct MLM software as per the business plan suggested by the clients.This MLM software is featured of with integrated with SMS, E-Wallet,Replicating Website,E-Pin,E-Commerce, Shopping Cart,Web Design and more.",
    //       "field_name": "become_affiliate",
    //       "value": false
    //     });
    //   }
    // }

    setState((prev) => ({
      ...prev,
      [key]: newData,
    }));
    // update the state and reset the caret
    console.log("dataaaa", newData)
  };

  const PhoneCodeChangeHandler = (e, countrycode, flag) => {
    console.log("countrycodedd", countrycode)
    const { name, value } = e.target
    if (name == "mobile") {
      console.log("newdataa")
      const newOptions = state.contactInfo.map((contactItems) => {
        if (contactItems.code === "mobile") {
          console.log("enterhjj")
          return {
            ...contactItems,
            phone_code: countrycode,
            value: value,
            flag: flag
          };
        }

        return contactItems
      })

      setState(prev => ({
        ...prev,
        contactInfo: newOptions
      }))



    }
  }

  const PhonecodeBlurhandler = (code, flag) => {
    const FindPhone_code = state.contactInfo.find((obj) => obj.code === "mobile").phone_code
    console.log("FindPhone_code", FindPhone_code);
    if (FindPhone_code !== code || FindPhone_code == '') {
      const newOptions = state.contactInfo.map((contactItems) => {
        if (contactItems.code === "mobile") {
          console.log("enterhjj")
          return {
            ...contactItems,
            phone_code: code,
            flag: flag
          };
        }

        return contactItems
      })

      setState(prev => ({
        ...prev,
        contactInfo: newOptions
      }))

    }
  }
  console.log("contactinfooo", state.contactInfo)
  const otpModel = (e, checked) => {
    let tempValue = checked == "false" ? true : false;
    let NewData = state?.contactInfo.map((obj) => {
      if (obj.code == "phone_validation") {
        return {
          ...obj,
          value: !tempValue,
        };
      } else {
        return obj;
      }
    });
    setState({
      ...state,
      contactInfo: NewData,
    });
    if (e.target.checked) {
      const service = new ReplicaRegService();
      let formData = {};

      formData.phone_number =
        "+" +
        state?.contactInfo.find((obj) => obj.code == "mobile")?.country_code +
        state?.contactInfo.find((obj) => obj.code == "mobile")?.value;
      service.ResendOtp(formData).then((res) => {
        if (res.status) {
          setotpModal((prev) => ({
            ...prev,
            show: true,
          }));
        } else {
          setotpModal((prev) => ({
            ...prev,
            show: true,
          }));
        }
      });
    } else {
    }
  };

  const MailOtpModel = (e, checked) => {
    let tempValue = checked == "false" ? true : false;
    let NewData = state?.contactInfo.map((obj) => {
      if (obj.code == "mail_validation") {
        return {
          ...obj,
          value: !tempValue,
        };
      } else {
        return obj;
      }
    });
    setState({
      ...state,
      contactInfo: NewData,
    });
    if (e.target.checked) {
      const service = new ReplicaRegService();
      let formData = {};
      formData.email = state?.contactInfo.find((obj) => obj.code == "email")?.value;
      service.ResendMailOtp(formData).then((res) => {
        if (res.status) {
          setmailotpModal((prev) => ({
            ...prev,
            show: true,
          }));
        } else {
          setmailotpModal((prev) => ({
            ...prev,
            show: true,
          }));
        }
      });
    }
    else {
    }
  }
  const onOtpSubmitForm = (e, otp) => {

    const service = new ReplicaRegService();
    e.preventDefault();
    let isValid = true;
    if (isValid) {
      let formData = {};

      formData.otp = otp;
      formData.phone_number =
        "+" +
        state?.contactInfo.find((obj) => obj.code == "mobile")?.country_code +
        state?.contactInfo.find((obj) => obj.code == "mobile")?.value;


      service.VerifyOtp(formData).then((res) => {
        if (res.status) {
          setState((prev) => ({
            ...prev,
            alert: {
              show: true,
              message: res.data.message,
              varient: "success",
            },
          }));
          setotpModal((prev) => ({
            ...prev,
            show: false,
          }));
        } else {
          let NewData = state?.contactInfo.map((obj) => {
            if (obj.code == "phone_validation") {
              return {
                ...obj,
                value: false,
              };
            } else {
              return obj;
            }
          });
          setState({
            ...state,
            contactInfo: NewData,
            alert: {
              show: true,
              message: res.error.description,
              varient: "danger",
            },
          });
        }
      });
    }
  };


  const onmailOtpSubmitForm = (e, otp) => {

    const service = new ReplicaRegService();
    e.preventDefault();
    let isValid = true;
    if (isValid) {
      let formData = {};

      formData.otp = otp;
      formData.mail = state?.contactInfo.find((obj) => obj.code == "email")?.value;
      service.VerifyOtp(formData).then((res) => {
        if (res.status) {
          setState((prev) => ({
            ...prev,
            mailalert: {
              show: true,
              message: res.data.message,
              varient: "success",
            },
          }));
          setmailotpModal((prev) => ({
            ...prev,
            show: false,
          }));
        } else {
          let NewData = state?.contactInfo.map((obj) => {
            if (obj.code == "mail_validation") {
              return {
                ...obj,
                value: false,
              };
            } else {
              return obj;
            }
          });
          setState({
            ...state,
            contactInfo: NewData,
            mailalert: {
              show: true,
              message: res.error.description,
              varient: "danger",
            },
          });
        }
      });
    }
  };

  const resendHandler = () => {
    setotpModal((prev) => ({
      ...prev,
      show: false,
    }));
    let NewData = state?.contactInfo.map((obj) => {
      if (obj.code == "phone_validation") {
        return {
          ...obj,
          value: false,
        };
      } else {
        return obj;
      }
    });
    setState({
      ...state,
      contactInfo: NewData,
    });
  };

  const resendMailHandler = () => {
    setmailotpModal((prev) => ({
      ...prev,
      show: false,
    }));
    let NewData = state?.contactInfo.map((obj) => {
      if (obj.code == "mail_validation") {
        return {
          ...obj,
          value: false,
        };
      } else {
        return obj;
      }
    });
    setState({
      ...state,
      contactInfo: NewData,
    });
  };

  const closeAlert = () => {
    setState((prev) => ({
      ...prev,
      alert: {
        show: false,
        message: "",
        varient: "",
      },
    }));
  };


  let legValid = false;
  ///form submitting and form validation
  const submitForm = async (e, token, id) => {
    console.log("sada1", e, "sada2", token, "sada3")
    let isValid = true;
    let username = "";
    if (e.hasOwnProperty("defaultPrevented")) {
      e.preventDefault();
    }

    if (id !== "payment") {
      const newData = await Promise.all(
        state[id].map(async (items) => {
          if (!items.value && items.required) {
            isValid = false;
            let errorname = "required";
            if (items.type === "select") {
              errorname = "selectRequired";
            }
            else if (items.code === "agree_terms") {
              errorname = "termsCondietions";
            }
            else if (items.code === "become_affiliate") {
              errorname = "termsCondietions";
            }
            return {
              ...items,
              error: "validation." + errorname,
              errorField: {
                field: items.code,
              },
            };
          } else if (items.custom) {
            return {
              ...items,
              customField: { id: items.id, value: items.value }
            };

          }

          else if (items.code === "position") {
            await checkLeg(items, id);
            if (!legValid) {
              isValid = false;
              return {
                ...items,
                error: "validation.invalid",
                errorField: {
                  field: "position",
                },
              };
            }
          } else if (items.code === "email") {
            if (!emailVaidation(items.value)) {
              isValid = false;
              return {
                ...items,
                error: "validation.invalidEmail",
              };
            }
          } else if (items.code === "become_affiliate") {
            if (items.value == false) {
              isValid = false;
              return {
                ...items,
                error: "validation.termsCondietions"
              }
            }
          } else if (items.code === "password") {
            if (items.value.length < 8) {
              isValid = false;
              return {
                ...items,
                error: "validation.lengthcannotbeLessthan8"
              }
            }
          } else if (items.code == "country") {
            if (items.value === "") {

              isValid = false;
              return {
                ...items,
                error: "validation.selectCoutry",
              };
            } else {
              return {
                ...items,
                error: "",
              };
            }

          }
          else if (items.code === "mobile") {
            let valid = valiadtePhoneNumber(items);

            if (!valid) {
              isValid = false;
              return {
                ...items,
                error: "validation.invalidPhone",
              };
            } else {
              return {
                ...items,
                error: "",
              };
            }
          } else if (items.code === "userName") {
            username = items.value;
            if (
              items.validation.min_length &&
              items.value.length < items.validation.min_length
            ) {
              isValid = false;
              return {
                ...items,
                error: "validation.min_length",
                errorField: {
                  field: "userName",
                  length: items.validation.min_length,
                },
              };
            } else if (
              items.validation.max_length &&
              items.value.length > items.validation.max_length
            ) {
              isValid = false;
              return {
                ...items,
                error: "validation.max_length",
                errorField: {
                  field: "userName",
                  length: items.validation.max_length,
                },
              };
            } else if (!checkUsername(items.value)) {
              isValid = false;
              return {
                ...items,
                error: "validation.userNameUnavailable",
              };
            } else if (items.error) {
              isValid = false;
            }
          } else if (items.code === "confirmPassword") {
            let password = "";
            state.LoginInfo.some((element, index, arr) => {
              password = arr[index].value;
              return element.code === "password";
            });
            if (password !== items.value) {
              isValid = false;
              return {
                ...items,
                error: "validation.mismatch",
              };
            }
          } else if (items.code === "dateOfBirth") {
            let Now = moment().format("YYYY-MM-DD");

            if (
              moment(Now).diff(items.value, "years") <
              parseInt(items.validation.agelimit)
            ) {
              isValid = false;
              return {
                ...items,
                error: "validation.invalidAgelimit",
                errorField: {
                  age: items.validation.agelimit,
                },
              };
            }
            if (
              moment(new Date()).format("YYYY-MM-DD") <
              moment(items.value).format("YYYY-MM-DD")
            ) {
              isValid = false;
              return {
                ...items,
                error: "validation.inValidDate",
              };
            }

          } else if (items.code === "product") {
            let packages = "";
            let amount = 0;
            state.sponsor.some((element, index, arr) => {
              packages = arr[index];
              return element.code === "product";
            });
            packages.options.some((element, index, arr) => {
              amount = parseInt(arr[index].productValue);
              return element.value === packages.value;
            });
            setState((prev) => ({
              ...prev,
              amount: amount,
              product: items.value,
            }));
          }
          return items;
        })
      );
      setState((prev) => ({
        ...prev,
        [id]: newData,
        userName: username,
      }));
    }
    if (isValid) {
      if (page === steps.length - 1) {
        let formData = {};
        for (const [key] of Object.entries(state)) {
          if (["sponsor", "contactInfo", "LoginInfo"].includes(key)) {
            state[key].map((items) => {
              if (items.customField) {
                state.customFields.push(items.customField)
                formData.customFields = state.customFields;
              } else {
                formData[items.field_name] = items.value;
              }
              return false;
            });
          }
        }
        formData.payment_method = payment;
        if (payment === "ewallet") {
          formData.user_name_ewallet = ewalletData.username;
          formData.tran_pass_ewallet = ewalletData.password;
        } else if (payment === "epin") {
          formData.pin_array = state.pinArray.length;
          state.pinArray.forEach((element, index) => {
            formData["epin" + (index + 1)] = element.pin;
          });
        } else if (payment === "paypal") {
          formData.paypal_token = state.paypal.orderId;
          formData.PayerID = state.paypal.payeerId;
          formData.currency = "USD";
        } else if (payment === "stripe") {
          formData.stripe_token = e;
          formData.currency = "USD";
        }
        if (params.leg) {
          formData["position"] = params.leg;
        }
        if (params.reg_from_tree) {
          formData["reg_from_tree"] = true;
        }
        // return false
        // formData.placement_user_name = '';
        setLoading(true)
        servcie.regsiterSubmit(formData).then((res) => {
          if (res.status) {
            setLoading(false)
            history.push({
              pathname: "/preview/" + res.data.userName,
              data: {
                message: res.data.code,
                username: res.data.userName,
              },
            });
          } else {
            setLoading(false)
            //validation error
            let errorKey = "";
            let errorMessage = "";
            if (res.error.code === 1004) {
              Object.keys(res.error.fields).map(function (key, index) {
                if (key.substr(key.length - 4) !== "_err") {
                  Object.keys(state).map((stateitems, index) => {
                    if (
                      ["sponsor", "contactInfo", "LoginInfo"].includes(
                        stateitems
                      )
                    ) {
                      let data = state[stateitems].map((items) => {
                        if (items.field_name === key) {
                          if (!errorKey) {
                            errorKey = stateitems;
                            errorMessage = res.error.fields[key + "_err"];
                          }
                          return {
                            ...items,
                            error: t(
                              "validation." + res.error.fields[key + "_err"]
                            ),
                          };
                        }
                        return items;
                      });
                      setState((prev) => ({
                        ...prev,
                        [stateitems]: data,
                      }));
                    }

                    return false;
                  });
                }
                return false;
              });
            }
            else if (res.error.code === 1025) {
              errorMessage = "insufficientBalance"
            } else if (res.error.code === 1062) {
              errorMessage = "mailAlreadyexist"
            }
            else if (res.error.code === 1006) {
              errorMessage = "registrationBlocked"
            } else if (res.error.code === 1034) {
              errorMessage = "registrationNotAllowed"
            } else if (res.error.code === 1035) {
              errorMessage = "invalidSponsorUsername"
            } else if (res.error.code === 1036) {
              errorMessage = "invalidPaymentMethod"
            } else if (res.error.code === 1016) {
              errorMessage = "invalidEpin"
            } else if (res.error.code === 1015) {
              errorMessage = "invalidTransactionDetails"
            } else if (res.error.code === 1011) {
              errorMessage = "userNameUnavailable"
            } else if (res.error.code === 1082) {
              errorMessage = "registrationFAiled"
            } else if (res.error.code === 1010) {
              errorMessage = "usernameAlreadyexits"
            } else if (res.error.code === 1002) {
              history.push('/logout');
            }
            setState((prev) => ({
              ...prev,
              errorMessage: t("validation." + errorMessage),
              submitError: true,
            }));

            //redierect the wizard page
            switch (errorKey) {
              case "sponsor":
                setPage(0);
                break;
              case "contactInfo":
                setPage(1);
                break;
              case "LoginInfo":
                setPage(2);
                break;
              default:
                break;
            }
          }
        });
      } else {
        nextPage();
      }
    }
  };

  //blur handler
  const blurhandler = async (key) => {
    if (key === "password") {
      let Policyvalid = true;
      let NewData = state.LoginInfo.map((items) => {
        if (items.code === "password") {
          Object.keys(items.validation).map(function (key, index) {
            if (key !== "disableHelper") {
              if (!passwordPolicy[key]) {
                Policyvalid = false;
              }
            }
            return false;
          });
          if (!Policyvalid) {
            return {
              ...items,
              // error: "validation.passwordPolicyInvalid",
            };
          }
        }
        return items;
      });
      setState((prev) => ({
        ...prev,
        LoginInfo: NewData,
      }));
    } else if (key === "sponsorUserName") {
      let sponsorData = "";
      state.sponsor.some((element, index, arr) => {
        sponsorData = arr[index];
        return element.code === "sponsorUserName";
      });
      const formData = {
        username: sponsorData.value,
      };
      servcie.validateSponsorUserName(formData).then((res) => {
        if (res.status) {
          const newData = state.sponsor.map((items) => {
            if (items.code === "sponsorFullName") {
              return {
                ...items,
                value: res.data.sponsorFullName,
              };
            }
            return items;
          });
          setState((prev) => ({
            ...prev,
            sponsor: newData,
          }));
        } else {
          if (res.error.code === 1002) {
            history.push("/logout");
          } else {
            const newData = state.sponsor.map((items) => {
              if (items.code === "sponsorUserName") {
                return {
                  ...items,
                  error: "validation.invalid",
                  errorField: {
                    field: "sponsorUserName",
                  },
                };
              } else if (items.code === "sponsorFullName") {
                return {
                  ...items,
                  value: "",
                };
              }
              return items;
            });
            setState((prev) => ({
              ...prev,
              sponsor: newData,
            }));
          }
        }
      });
    } else if (key === "userName") {
      let username = "";
      state.LoginInfo.some((element, index, arr) => {
        username = arr[index].value;
        return element.code === "userName";
      });
      if (!username) {
        let NewData = state.LoginInfo.map((items) => {
          if (items.code === "userName") {
            return {
              ...items,
              error: "validation.required",
              errorField: {
                field: "userName",
              },
            };
          }
          return items;
        });
        setState((prev) => ({
          ...prev,
          LoginInfo: NewData,
        }));
      } else if (!(await checkUsername(username))) {
        let NewData = state.LoginInfo.map((items) => {
          if (items.code === "userName") {
            return {
              ...items,
              error: "validation.userNameUnavailable",
            };
          }
          return items;
        });
        setState((prev) => ({
          ...prev,
          LoginInfo: NewData,
        }));
      }
    }
  };

  //check the username
  const checkUsername = async (username) => {
    let formData = {
      userName: username,
    };
    let usernameValid = false;
    await servcie.checkUsename(formData).then((res) => {
      if (!res.status) {
        if (res.error?.code === 1002) {
          history.push("/logout");
        }
        usernameValid = false;
        // return false;
      } else {
        usernameValid = true;
      }
    });
    return usernameValid;
  };

  //check the leg availabiliitycontactInformation
  const checkLeg = async (data, id) => {
    let placement = state[id].find(
      (element) => element.code === "placementUserName"
    );
    let sponsorData = "";
    state.sponsor.some((element, index, arr) => {
      sponsorData = arr[index];
      return element.code === "sponsorUserName";
    });
    if (sponsorData.value) {
      const formData = {
        sponsor_leg: data.value,
        sponsor_user_name: sponsorData.value,
        placement_user_name: "",
      };
      if (placement) {
        formData.placement_user_name = placement.value;
      }
      await servcie.checklegAvailability(formData).then((res) => {
        if (!res.status) {
          if (res.error.code === 1033) {
            return (legValid = false);
          } else if (res.error.code === 1002) {
            history.push("/logout");
          }
        }
        return (legValid = true);
      });
    }
  };

  //close handler terms and condietion modal
  const handlemodal = (content = "") => {
    setTermsAndCond((prev) => ({
      ...prev,
      show: !prev.show,
      content: content,
    }));
  };

  //validate email address
  const emailVaidation = (mail) => {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (mail.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  };
  //date select change

  const selectDate = (selectedDate, id) => {
    let NewData = state.contactInfo.map((items) => {
      if (items.code === "dateOfBirth") {
        return {
          ...items,
          value: selectedDate,
          error: "",
          errorField: {
            field: "",
          },
        };
      }
      return items;
    });
    setState((prev) => ({
      ...prev,
      contactInfo: NewData,
    }));
  };

  //change the payment method change
  const paymentMethodChange = (method) => {
    setPayment(state.PaymentMethods.fields[method]?.code);
  };

  //get the ewallet data
  const getEwalletData = (username, password) => {
    setEwalletData({
      username: username,
      password: password,
    });
  };

  //get Epin Data
  const getEpinData = (pinArray) => {
    if (pinArray.length > 0) {
      setState((prev) => ({
        ...prev,
        pinArray: pinArray,
      }));
    }
  };

  //get the paypal data
  const paypalData = (data) => {
    console.log("my data12333", data)
    if (data) {
      setState((prev) => ({
        ...prev,
        paypal: {
          ...prev.paypal,
          orderId: data.orderID,
          payeerId: data.payerID,
        },
      }));
    }
  };
  return (
    <div className={style.mainContent}>
      <Row>
        <Col md={{ span: 7, offset: 2 }}>
          <Stepper
            steps={steps}
            activeStep={page}
            lineMarginOffset={0}
            size={40}
            stepsBorderRadius="50%"
            barHeight={5}
          />
          <Alert variant="danger" dismissible show={state.submitError}>
            {state.errorMessage}
          </Alert>
          {page === 0 && (
            <SponsorPackage
              submit={submitForm}
              data={state.sponsor}
              ChangeHandler={ChangeHandler}
              title={props.data.sponsor.title}
              blurhandler={blurhandler}
            />
          )}
          {page === 1 && (
            <div>
              <ContactInformation
                submit={submitForm}
                data={state.contactInfo}
                ChangeHandler={ChangeHandler}
                prevButtonClick={prevPage}
                selectDate={selectDate}
                otpModel={otpModel}
                MailOtpModel={MailOtpModel}
                PhoneCodeChangeHandler={PhoneCodeChangeHandler}
                PhonecodeBlurhandler={PhonecodeBlurhandler}
              />
            </div>
          )}
          {page === 2 && (
            <LoginInformation
              loader={loading}
              submit={submitForm}
              data={state.LoginInfo}
              ChangeHandler={ChangeHandler}
              prevButtonClick={prevPage}
              openModal={handlemodal}
              policy={passwordPolicy}
              blurhandler={blurhandler}
              userType={state.userType}
              enableFinish={page === steps.length - 1}
            />
          )}
          {page === 3 && (
            <Payment
              productAmount={state.amount}
              data={state.PaymentMethods}
              prevButtonClick={prevPage}
              submit={submitForm}
              paymentMethodChange={paymentMethodChange}
              userName={state.userName}
              product={state.product}
              ewallet={getEwalletData}
              epin={getEpinData}
              getPaypalData={paypalData}
              payment_type="registration"
              loader={loading}
            />
          )}
        </Col>
      </Row>
      <TAndC data={termsAndCond} handleClose={handlemodal} />

      <Modal show={otpModals.show} onHide={resendHandler}>
        <Modal.Header className={`${modalstyle.ModalHeader}`} closeButton>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={resendHandler}
          ></button>

          <Modal.Title className={`text-center`}>{props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body className={`p-0`}>
          <VerificationForm
            data={state.contactInfo}
            dismiss={resendHandler}
            onOtpSubmitForm={onOtpSubmitForm}
            alert={state.alert}
            closeAlert={closeAlert}
          ></VerificationForm>
        </Modal.Body>
      </Modal>

      <Modal show={MailotpModals.show} onHide={resendMailHandler}>
        <Modal.Header className={`${modalstyle.ModalHeader}`} closeButton>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={resendMailHandler}
          ></button>

          <Modal.Title className={`text-center`}>{props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body className={`p-0`}>
          <Mailotp
            data={state.contactInfo}
            dismiss={resendMailHandler}
            onmailOtpSubmitForm={onmailOtpSubmitForm}
            alert={state.mailalert}
            closeAlert={closeAlert}
          ></Mailotp>
        </Modal.Body>
      </Modal>

    </div>
  );
}

export default Content;
