import React, { useEffect, useState } from "react";
// import {Tabs,Tab} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
//compoents
import NFT_Table from "./NFT_Table";
import Tabs from "react-responsive-tabs";
import style from "./nft.module.scss";
import { propTypes } from "react-bootstrap/esm/Image";
import { useSelector } from "react-redux";
import Stake_report from "./Stake_report";
import FractionalizedStatementReport from "./FractionalizedStatementReport";

const stylees = {
    paddingTop: "10px",
    fontFamily: "system-ui",
};
function Contnet({
    tableData,
    changeHandler,
    paginationHandler,
    repurchase_status,
    exportData,
    loader,
}) {
    const { currentTheme } = useSelector((state) => state.Theme);
    const { t } = useTranslation();
    const [tabs, setTabs] = useState([
        "purchase_report",
        "stake_report",
        "fractionalized"
    ]);
    const getTabs = () => {
        return tabs.map((tab, index) => ({
            title: t("Common." + tab),
            getContent: () => getContent(tab),
            /* Optional parameters */
            key: tab,
            tabClassName: style.TabClass,
            panelClassName: style.TabPanel,
            // tabsWrapperClass : 'safdsadfads'
        }));
    };

    const getContent = (contentId) => {
        if (contentId === "purchase_report") {
            return (
                <div className={style.dataTable} style={stylees}>
                    <NFT_Table
                        ContentData={tableData.purchase_report}
                        paginationchange={paginationHandler.purchase_report}
                    />
                </div>
            );
        }
        else if (contentId === "stake_report") {
            return (
                <div className={style.dataTable} style={stylees}>
                    <Stake_report
                        loader={loader}
                        ContentData={tableData.stakeTable}
                        paginationchange={paginationHandler.stake_report}
                    />
                </div>
            );
        } else if (contentId === "fractionalized") {
            return(
                <div className={style.dataTable} style={stylees}>
                <FractionalizedStatementReport
                    ContentData={tableData.fractionalizedTable}
                    paginationchange={paginationHandler.fractionalized_report}
                    tab={contentId}
                />
            </div>
            )
            
        }
      
    };
    return (
        <div className={style.summary}>
            <div
                className={`${currentTheme == "theme-dark"
                    ? `${style.dark_mode_tabContent}`
                    : `${style.tabContent}`
                    }`}
            >
                <Tabs
                    items={getTabs()}
                    transform={true}
                    transformWidth={734}
                    onChange={changeHandler}
                    selectedTabKey="purchase_report"
                />
            </div>
        </div>
    );
}

export default Contnet