import React from "react";
import { Dropdown } from "react-bootstrap";
import style from "./dashboard.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useState } from "react";
const FilterToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    className={style.TilesfilterIcon}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </span>
));
function TilesFilter(props) {
  console.log("TilesFilter",props)
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  
  return (
    <>
    {
    "showIcon" in props ? (
     <div className={`${style.lst_box_quick_select}`}>
     <div className={style.lst_box_quick_select_btn}>
       <i
         style={{ color: props.showIcon ? "green" : "#DF3B3B" ,fontSize:"18px"}}
         className={props.showIcon ? "fa fa-eye":"fa fa-eye-slash" } aria-hidden="true" 
         onClick={props.onChange}
       ></i>
     </div>
     </div>
    
    ):(

      <Dropdown className={`${style.lst_box_quick_select}`}>
        <Dropdown.Toggle className={style.lst_box_quick_select_btn}>
          <i
            style={{ color: currentTheme === "theme-dark" ? "white" : "black" }}
            className="fa fa-ellipsis-v"
          ></i>
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight className={style.dropdownmenu}>
          {props.items &&
            props.items.map((item, index) => {
              
              return (
                <Dropdown.Item
                  className={style.dropdownitem}
                  key={index}
                  eventKey={item.code}
                  onSelect={props.onChange}
                >
                  {t("Common." + item.code)}
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    )}
    </>
  );
}

export default TilesFilter;
