import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
// import fileDownload from 'js-file-download';
//components
import PageTitle from "../../components/common/pageTitle";
import Tiles from "../../components/NFT/Tiles";
import Content from "../../components/NFT/Contnet"
import { FormInputControl } from "../../components/common";
import Spinner from "../../shared/Spinner";
import { useSelector } from "react-redux";

//style
import style from "../../components/NFT/nft.module.scss";

import PaypalPayment from "../../components/Payments/PaypalPayment";
import Payments from "../../../service/common/PaymentApi";

//service
import NFTService from "../../../service/NFT/nft";

import UserStore from "../../../store/UserStore";

//data
// import ewalletdata from "./ewalletdata.json";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import companyName from "../../../store/mobxStore/companyName";
import { useTranslation } from "react-i18next";
import Gallery from "../../components/NFT/Gallery";




const mapStateToProps = (state) => ({ lang: state.Lang });
function NFT(props) {
  const Currency = useSelector((state) => state.curr);

  const { t } = useTranslation();
  const [checkout, setCheckout] = useState(false);
  // let amount = 10;
  const [EwalletAmount, setEwalletAmount] = useState({
    amount: "",
    FormError: {
      amount: "",
    },
    isvalid: false,
  });

  const [loader, setLoader] = useState({
    EarningsTable: {
      search: false,
      reset: false,
      excel: false,
      csv: false,
      print: false,
    },
    HistoryTable: {
      search: false,
      reset: false,
    },
  });

  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  const changeHandler = (e) => {
    const { name, value } = e.target;

    setEwalletAmount((prev) => ({
      ...prev,
      [name]: value,
      FormError: {
        ...prev.FormError,
        [name]: "",
      },
      isvalid: false,
    }));
  };

  const [state, setState] = useState({
    Modal: {
      fundTransfer: false,
      addWallet: false,
    },
    tilesData: [],
    balanceAmount: "",
    TransactionFee: "",
    repurchase_status: "yes",
    loading: true,
    tab: '',
    nftGallery:[],
    DataTables: {
      key: UserStore.checkUserEarnings ? "earning" : "statement",
      purchase_report: {
        totalRows: 0,
        data: [],
        pending: true,
        perPage: 10,
        inPage: 1,
      },
      stakeTable: {
        totalRows: 0,
        data: [],
        pending: true,
        perPage: 10,
        inPage: 1,
        startDate: moment().subtract(29, "days"),
        endDate: moment(),
        direction: "",
      },
      fractionalizedTable: {
        totalRows: 0,
        data: [],
        pending: true,
        perPage: 10,
        inPage: 1,
        startDate: moment().subtract(29, "days"),
        endDate: moment(),
        direction: "",
      },

      earnings: {
        totalRows: 0,
        data: [],
        pending: true,
        perPage: 10,
        inPage: 1,
        startDate: moment().subtract(29, "days"),
        endDate: moment(),
        direction: "",
        category: [],
      },
    },
  });
  const [clientSecret, setClientSecret] = useState("");
  const service = new NFTService();
  const Service = new Payments();

  useEffect(() => {
    Service.createPayment(props.product, "Paypal").then((res) => {
      if (res.status) {
        setClientSecret(res.data.public_key);
      }
    });
    history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
        if (location.pathname === "/dashboard") {
          UserStore.checkUserEarnings = false;
        }
      }
      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          UserStore.checkUserEarnings = false;
        }
      }
    });

    //componentdidmount
    loadData();
  }, [props, locationKeys, Currency.currentCurr]);

  const checkAvailable = () => {
    let valid = true;

    if (!EwalletAmount.amount) {
      valid = false;
      setEwalletAmount((prev) => ({
        ...prev,
        FormError: {
          ...prev.FormError,
          amount: t("validation.required", { field: "amount" }),
        },
      }));
    }
    if (valid) {
      setCheckout(true);
    }
  };

  //load the data
  const loadData = () => {
    //ewallet tiles
    service.Tiles().then((res) => {
      if (res.status) {
        console.log('jjjjkk',res.data.nft_details)
        setState((prevState) => ({
          ...prevState,
          tilesData: res.data.nft_tile,
          balanceAmount: res.data.balance,
          TransactionFee: res.data.transactionFee,
          repurchase_status: res.data.repurchase_status,
          purchase_wallet: res.data.purchase_wallet,
          nftGallery:res.data.nft_details,
          loading: false,
        }));
      } else {
        props.history.push("/logout");
      }
    });
    getPurchaseReportTable();
    getStakeTable();
    // getWalletTable();
    // getUserEarningsTable();
    // this.getHistoryTable();
  };

  //statement table
  const getPurchaseReportTable = (
    inPage = state.DataTables.purchase_report.inPage,
    perPage = state.DataTables.purchase_report.perPage,
    startDate = moment().startOf("month").format("MMMM D, YYYY"),
    endDate = moment().format("MMMM D, YYYY"),
  ) => {
    let start = (inPage - 1) * perPage;
    service.purchaseReport(start, perPage, startDate, endDate).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          DataTables: {
            ...prevState.DataTables,
            purchase_report: {
              ...prevState.DataTables.purchase_report,
              totalRows: res.data.count,
              data: res.data.result,
              pending: false,
            },
          },
        }));
      } else {
        props.history.push("/logout");
      }
    });
  };

  //change statement table
  const changePurchaseReportTable = (inPage, perPage, start, end,) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        purchase_report: {
          ...prevState.DataTables.purchase_report,
          perPage: perPage,
          inPage: inPage,
          // pending     : false
        },
      },
    }));
    getPurchaseReportTable(inPage, perPage, start, end);
  };

  //get history tabla data
  const getStakeTable = (
    inPage = state.DataTables.stakeTable.inPage,
    perPage = state.DataTables.stakeTable.perPage,
    startDate = moment().startOf("month").format("MMMM D, YYYY"),
    endDate = moment().format("MMMM D, YYYY"),
  ) => {
    let start = (inPage - 1) * perPage;
    service.stake_report(start, perPage, startDate, endDate)
      .then((res) => {
        if (res.status) {

          setState((prevState) => ({
            ...prevState,
            DataTables: {
              ...prevState.DataTables,
              stakeTable: {
                ...prevState.DataTables.stakeTable,
                totalRows: res.data.count,
                data: res.data.result,
                pending: false,
              },
            },
          }));
        } else {
          props.history.push("/logout");
        }
      });
  };

  //change table data
  const changeStakeTable = (
    inPage,
    perPage,
    start,
    end,
  ) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        stakeTable: {
          ...prevState.DataTables.stakeTable,
          perPage: perPage,
          inPage: inPage,
          // pending     : true,
          startDate: start,
          endDate: end,
        },
      },
    }));
    getStakeTable(inPage, perPage, start, end);
  };


  const getFractionalizedStatement = (
    inPage = state.DataTables.fractionalizedTable.inPage,
    perPage = state.DataTables.fractionalizedTable.perPage,
    startDate = moment().startOf("month").format("MMMM D, YYYY"),
    endDate = moment().format("MMMM D, YYYY"),
    
  ) => {
    let start = (inPage - 1) * perPage;
    service.FractionalizedReport(start, perPage, startDate, endDate)
      .then((res) => {
        if (res.status) {

          setState((prevState) => ({
            ...prevState,
            DataTables: {
              ...prevState.DataTables,
              fractionalizedTable: {
                ...prevState.DataTables.fractionalizedTable,
                totalRows: res.data.count,
                data: res.data.result,
                pending: false,
              },
            },
          }));
        } else {
          props.history.push("/logout");
        }
      });
  };

  //change table data
  const changeFractionalizedStatement = (
    inPage,
    perPage,
    start,
    end,
    type = state.tab
  ) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        fractionalizedTable: {
          ...prevState.DataTables.fractionalizedTable,
          perPage: perPage,
          inPage: inPage,
          // pending     : true,
          startDate: start,
          endDate: end,
        },
      },
    }));
    getFractionalizedStatement(inPage, perPage, start, end, type);
  };





  //get the userearnings table
  const getUserEarningsTable = (
    inPage = state.DataTables.earnings.inPage,
    perPage = state.DataTables.earnings.perPage,
    direction = "",
    startDate = moment().startOf("month").format("MMMM D, YYYY"),
    endDate = moment().format("MMMM D, YYYY"),
    order = "",
    keys = ""
  ) => {
    let start = (inPage - 1) * perPage;
    service
      .earnigs(start, perPage, direction, startDate, endDate, order, keys)
      .then((res) => {
        if (res.status) {
          setLoader({
            EarningsTable: {
              search: false,
              reset: false,
              excel: false,
              csv: false,
              print: false,
            },
            HistoryTable: {
              search: false,
              reset: false,
            },
          });

          setState((prevState) => ({
            ...prevState,
            DataTables: {
              ...prevState.DataTables,
              earnings: {
                ...prevState.DataTables.earnings,
                totalRows: res.data.count,
                data: res.data.table_data,
                pending: false,
                category: res.data.category,
              },
            },
          }));
        } else {
          props.history.push("/logout");
        }
      });
  };

  //change table data
  const changeEarningsTable = (
    inPage,
    perPage,
    start,
    end,
    dierection,
    colKey,
    key
  ) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        earnings: {
          ...prevState.DataTables.earnings,
          perPage: perPage,
          inPage: inPage,
          // pending     : true,
          startDate: start,
          endDate: end,
          direction: dierection,
        },
      },
    }));
    getUserEarningsTable(inPage, perPage, dierection, start, end, colKey, key);
  };

  //open function in ewallet fund transfer modal
  const purchaseWallet = () => {
    setState((prevState) => ({
      ...prevState,
      Modal: {
        ...prevState.Modal,
        addWallet: true,
      },
    }));
  };

  //add purchase wallet modal open
  const fundTransferModal = () => {
    setState((prevState) => ({
      ...prevState,
      Modal: {
        ...prevState.Modal,
        fundTransfer: true,
      },
    }));
  };

  //modal close
  const handleClose = () => {
    setCheckout(false);
    setEwalletAmount((prevState) => ({
      ...prevState,
      amount: "",
    }));
    setState((prevState) => ({
      ...prevState,
      Modal: {
        ...prevState.Modal,
        fundTransfer: false,
        addWallet: false,
      },
    }));
  };
  const Translation = (data) => {
    let newData = data.map((item) => {
      if (item) {
        return {
          ...item,
          category: t("ewallet." + item.category),
          total_amount: `${Currency.currentCurr}${(
            parseFloat(item.total_amount) * Currency.value
          ).toFixed(Currency.precision)}`,
          service_charge: `${Currency.currentCurr}${(
            parseFloat(item.service_charge) * Currency.value
          ).toFixed(Currency.precision)}`,
          tax: `${Currency.currentCurr}${(
            parseFloat(item.tax) * Currency.value
          ).toFixed(Currency.precision)}`,
          amount_payable: `${Currency.currentCurr}${(
            parseFloat(item.amount_payable) * Currency.value
          ).toFixed(Currency.precision)}`,
        };
      } else return item;
    });

    return newData;
  };

  const ExportToExcel = (apiData, fileName, type) => {
    let fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    let fileExtension = ".xlsx";

    if (type === "excel") {
      console.log("apidata", Translation(apiData));
      // const exportToCSV = (apiData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(Translation(apiData));
      XLSX.utils.sheet_add_aoa(ws, [
        [
          "Category",
          "Total Amount",
          "Tax",
          "Service Charge",
          "Amount Payable",
          "Transaction Date",
        ],
      ]);

      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      setLoader({
        EarningsTable: {
          search: false,
          reset: false,
          excel: false,
          csv: false,
          print: false,
        },
        HistoryTable: {
          search: false,
          reset: false,
        },
      });
      // };
    } else if (type === "csv") {
      fileExtension = ".csv";
      let fileType = "text/csv;charset=utf-8";
      const ws = XLSX.utils.json_to_sheet(Translation(apiData));
      XLSX.utils.sheet_add_aoa(ws, [
        [
          "Category",
          "Total Amount",
          "Tax",
          "Service Charge",
          "Amount Payable",
          "Transaction Date",
        ],
      ]);
      const csvOutput = XLSX.utils.sheet_to_csv(ws);
      const data = new Blob([csvOutput], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
      setLoader({
        EarningsTable: {
          search: false,
          reset: false,
          excel: false,
          csv: false,
          print: false,
        },
        HistoryTable: {
          search: false,
          reset: false,
        },
      });
    } else if (type === "print") {
      //  let printView = <UserEarningsPrintView
      //      data={apiData}
      // />
    }
  };

  // Export Data
  const exportData = (startdate = "", enddate = "", categories = "", type) => {
    console.log("exportData", type);
    let keys = categories
      .map((key, idx) => {
        return "categories[" + idx + "]=" + key.value;
      })
      .join("&");

    if (type === "excel") {
      setLoader({
        EarningsTable: {
          search: false,
          reset: false,
          excel: true,
          csv: false,
          print: false,
        },
        HistoryTable: {
          search: false,
          reset: false,
        },
      });
    }
    if (type === "print") {
      setLoader({
        EarningsTable: {
          search: false,
          reset: false,
          excel: false,
          csv: false,
          print: true,
        },
        HistoryTable: {
          search: false,
          reset: false,
        },
      });
    }
    if (type === "csv") {
      setLoader({
        EarningsTable: {
          search: false,
          reset: false,
          excel: false,
          csv: true,
        },
        HistoryTable: {
          search: false,
          reset: false,
        },
      });
    }
    service.exportData(startdate, enddate, keys, type).then((res) => {
      if (type === "excel") {
        ExportToExcel(res.data, "User Earnigs Report", type);
      }
      if (type === "csv") {
        ExportToExcel(res.data, "User Earnigs Report", type);
      }

      if (type === "print") {
        ExportToExcel(res.data, "User Earnigs Report", type);
      }

      // if(type==='excel'){
      //     fileDownload(res, 'User Earnigs Report.xlsx');
      // }
    });
  };

  //tab change function(ddn't added this)
  const tabChangeHandler = (tab) => {
    console.log("tabbbb", tab)
    setState((prevState) => ({
      ...prevState,
      tab: tab,
      DataTables: {
        ...prevState.DataTables,
        purchase_report: {
          ...prevState.DataTables.purchase_report,
          perPage: 10,
          inPage: 1,
        },
        history: {
          ...prevState.DataTables.history,
          perPage: 10,
          inPage: 1,
        },
        wallet: {
          ...prevState.DataTables.wallet,
          perPage: 10,
          inPage: 1,
        },
        earnings: {
          ...prevState.DataTables.earnings,
          perPage: 10,
          inPage: 1,
        },
      },
    }));

    getPurchaseReportTable();
    getStakeTable();
    getFractionalizedStatement(
      state.DataTables.fractionalizedTable.inPage,
      state.DataTables.fractionalizedTable.perPage,
      moment().startOf("month").format("MMMM D, YYYY"),
      moment().format("MMMM D, YYYY"),
      tab
    )
    // getWalletTable();
    // getUserEarningsTable();
  };



  const paginationHandler = {
    purchase_report: changePurchaseReportTable,
    stake_report: changeStakeTable,
    fractionalized_report: changeFractionalizedStatement
    // wallet: changeWalletTable,
    // earnings: changeEarningsTable,
  };
  return (
    <div className={`h-100`}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.mynft")}
        </title>
        <script src={`https://www.paypal.com/sdk/js?client-id=${clientSecret}&currency=USD`}></script>
      </Helmet>
      <PageTitle title="mynft" />
      {state.loading ? (
        <Spinner />
      ) : (
        <>

          <div className={style.MainContainer}>
            <div className="mb-5">
              <Gallery 
              data={state.nftGallery}/>
            </div>
            {state.tilesData && (
              <Tiles
                tilesData={state.tilesData}
                repurchase_status={state.purchase_wallet}
              />
            )}
            <Content
              tableData={state.DataTables}
              changeHandler={tabChangeHandler}
              paginationHandler={paginationHandler}
              repurchase_status={state.purchase_wallet}
              exportData={exportData}
              loader={loader}
            />

          </div></>

      )}
    </div>
  )
}

export default NFT