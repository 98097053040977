import React, { useCallback, useEffect, useState } from "react";
import style from "../crm/crm.module.scss";

import { Form } from "react-bootstrap";
import {
  DatePickers,
  FormInputControl,
  DropDownBs,
  CustomButton,
  AlertBs,
} from "../common";
//import moment from 'moment';
//service
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CrmService from "../../../service/crm/Crm";
import AppinfoService from "../../../service/common/Appinfo";
//cookie
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import ReplicaRegService from "../../../service/Auth/Replicareg";

const InitialForm = {
  otp: "",

  formError: {
    otp: "",
  },
  editLoader: false,
};


function VerificationForm(props) {
  console.log("verificationfoprm", props)
  const { currentTheme } = useSelector((state) => state.Theme);

  const visitorid = localStorage.getItem("visitorid");
  const { t } = useTranslation();
  const history = useHistory();
  const [state, setState] = useState({
    ...InitialForm,
    alert: {
      show: false,
      message: "",
      varient: "",
    },
    editLoader: false,
  });
  const commonService = new ReplicaRegService();
  useEffect(() => {
    // resendHandler();
  }, []);

  //changeHandler
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
      formError: {
        ...prev.formError,
        [name]: "",
      },
    }));
  };

  function setCookie1(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  //submit the
  const onOtpSubmitForm = (e) => {
    e.preventDefault();
    props.onOtpSubmitForm(e, state.otp, "mail");
  };

  return (
    <div
      className={`${currentTheme == "theme-dark"
        ? `${style.dark_mode_panel} ${style.papanel_defaultnel}`
        : `${style.panel} ${style.papanel_defaultnel}`
        }`}
    >
      <div
        className={`${style.panel_body} ${style.otp_pop_sc}`}
        style={{ padding: "14%" }}
      >
        {console.log("props.alet", props.alert)}
        {
          props.alert && (
            <AlertBs {...props.alert} close={props.closeAlert} />
          )
        }

        <Form onSubmit={onOtpSubmitForm}>
          <div
            style={{
              fontSize: "12px",
              margin: "7px",
            }}
          >
            {" "}
            We have sent an OTP to this number {"+"}{" "}
            {props.data.find((obj) => obj.code == "mobile")?.country_code}{" "}
            {props.data.find((obj) => obj.code == "mobile")?.value}
          </div>
          <FormInputControl
            inputProps={{
              type: "text",
              name: "otp",
              placeholder: "Enter OTP",
              value: state.otp,
              onChange: changeHandler,
            }}
          />
          <div style={{ textAlign: "center" }}>
            <div>
              If you don't receive a code
              {/* <div><a  style={{textDecoration : "none",color :"lightblue"}} onClick={resendHandler}>Resend</a></div> */}
              <div
                style={{
                  border: "none",
                  background: "transparent",
                  color: "#3d78e4",
                  display: "inline-block",
                  marginBottom: "20px",
                  marginLeft: "3px",
                  cursor: "pointer",
                }}
              //  onClick={resendHandler}
              >
                {" "}
                Resend
              </div>
            </div>

            <CustomButton
              type="submit"
              variant="primary"
              disabled={state.editLoader}
            >
              {state.editLoader ? (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
                />
              ) : (
                ""
              )}

              {"Verify OTP"}
            </CustomButton>
          </div>
        </Form>
      </div>
    </div>
  );
}
export default VerificationForm;
