// check the user is logged
export const  islogged = () =>{
    return {
        type    : 'SIGN_IN'
    }
}
//log out 
export const logout = () =>{
    return {
        type    : 'LOG_OUT'
    }
}

//Laguage change  actions  in redux 
export const changeLang = (lang) =>{
    return {
        type    : 'CHANGE_LANG',
        lang    : lang
    }
}

//changing theme
export const changTheme = (theme) => {
    return {
      type: "CHANGE_THEME",
      theme: theme,
    };
  };

//get add the language
export const addLang = (lang) =>{
    return {
        type : 'ADD_Lang',
        lang : lang
    }
}

//get add the logouttime
export const addLogoutTime = (time,status) =>{
    return {
        type : 'ADD_LogoutTime',
        time : time,
        status : status
    }
}
//Laguage change  actions  in redux 
export const changeReplLang = (lang) =>{
    return {
        type    : 'REPLICA_CHANGE_LANG',
        repLang    : lang
    }
}

//get add the language
export const addReplLang = (lang) =>{
    return {
        type : 'REPLICA_ADD_Lang',
        repLang : lang
    }
}

//add currency
export const addCurrency = (curr) =>{
    return {
        type : 'ADD_CURR',
        curr : curr
    }
}

//change currency
export const changeCurr = (curr,value,precision,id) =>{
    return {
        type : 'CHANGE_CURR',
        curr : curr,
        value: value,
        precision: precision,
        id : id
    }
}

//change currency status
export const changeCurrStatus = (status) =>{
    return {
        type : 'CHANGE_CURR_STATUS',
        status : status
    }
}


//menus 
export const addMenus = (menu) =>{
    return {
        type : "ADD_MENU",
        menu : menu
    }
}
//menus change 
export const changeMenu = (menu) =>{
    return {
        type : "CHANGE_MENU",
        menu : menu
    }
}

//add the notification
export const addNotify =(value) =>{
    return { 
        type : 'Add_Notificcation',
        notification : value
    }
}

//affiliate status
export const AffiliateSatus=(sts)=>{
    return{
        type:'Affiliate_Status',
        affiliateStatus:sts
    }
}
//mail notification
export const addMail = (count,data) =>{
    return { 
        type : 'Add_Mail',
        count : count,
        data: data
    }
}

export const changePlan = (plan) =>{
    return { 
        type : 'CHANGE_PLAN',
        plan : plan
    }
}

export const addUsername = (username)=>{
    return { 
        type : 'ADD_USERNAME',
        name : username
    }
}