import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./mail.module.scss";
function MailType(props) {
  console.log('mailtype',props)
  const { t } = useTranslation();
  return (
    <Form.Group className={style.type}>
      <Form.Label className={style.radiolabel}>{t("Common.type")}</Form.Label>
      <div>
        <label className={style.radioButton}>
          <input
            type="radio"
            value="admin"
            name="type"
            onChange={props.changeHandler}
            checked={props.state.type === "admin"}
            // checked
          />
          {t("Common.admin")}
        </label>
      </div>
      <div>
        <label className={style.radioButton}>
          <input
            type="radio"
            value="myTeam"
            name="type"
            onChange={props.changeHandler}
            checked={props.state.type === "myTeam"}
          />
          {t("Common.myTeam")}
        </label>
      </div>
      <div>
        <label className={style.radioButton}>
          <input
            type="radio"
            value="individual"
            name="type"
            onChange={props.changeHandler}
            checked={props.state.type === "individual"}
          />
          {t("Common.individual")}
        </label>
      </div>
      <div>
        <label className={style.radioButton}>
          <input
            type="radio"
            value="level"
            name="type"
            onChange={props.changeHandler}
            checked={props.state.type === "level"}
          />
          {t("Common.level")}
        </label>
      </div>
      {/*<div>
                <label className={style.radioButton}>
                    <input
                    type="radio"
                    value="externalMail"
                    name="type"
                    onChange={props.changeHandler}
                    checked={props.state.type === "externalMail"}
                    />
                    {t('Common.externalMail')}
                </label>
    </div>*/}
    </Form.Group>
  );
}

export default MailType;
