import React from 'react'
import { useLocation } from 'react-router'
import { Card } from 'react-bootstrap';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNotify } from '../../../store/action';
import AppInfoservice from '../../../service/common/Appinfo';
import style from './announcement.module.scss'
import CampaignIcon from '@mui/icons-material/Campaign';
import { useSelector } from 'react-redux';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import nodata from "../../../assets/images/nophoto/no-datas-found.png"
function Content() {
    const location = useLocation();
    const [Announcement, setAnnouncement] = useState({})
    const dispatch = useDispatch();
    const theme = useSelector((state) => state)
    const service = new AppInfoservice();
    const { currentTheme } = useSelector((state) => state.Theme);
    console.log("announcementtt", location)
    useEffect(() => {
        if (location.state) {
            const { data } = location.state
            setAnnouncement(data)
            service.getNotification().then((res) => {
                if (res.status) {
                    dispatch(addNotify(res.data.notification_details))
                }
            })

        } else {
            return;
        }
    }, [location.state])
    return (
        <div className='m-3 mt-5'>
        
            <Accordion className={style.accordionContent}>
                <AccordionItem className={style.content} header={<>
                    <div style={{ display: 'flex' }}>
                        <KeyboardArrowDownOutlinedIcon />&nbsp;
                        <div  className={style.Contenttitle} dangerouslySetInnerHTML={{ __html: Announcement.title }}></div>
                    </div>


                </>}>
                    {
                        Object.keys(Announcement).length===0?
                        <center><img src={nodata} alt='NO data Found' style={{width:'200px'}}/>
                       
                        </center>
                        :
                        <div dangerouslySetInnerHTML={{ __html: Announcement.message }}></div>
                    }
                    

                    
                </AccordionItem>

            </Accordion>
        </div>
    )
}

export default Content