import React from "react";
import { Container } from "react-bootstrap";
import style from "./dashboard.module.scss";
import Profiles from "./profiles";
import Tile from "./Tile";
import EarningsTab from "./EarningsTab";
import TeamPerformance from "./TeamPerformance";
// import TeamPerformanceNew from './TeamPerformanceNew';
import Members from "./Members";
import Charts from "./charts";
import Ranks from "./Ranks";

import DashboardModel from "./DashboardModal";
import { useTranslation } from "react-i18next";

import AddForm from "./AddForm";
import VerificationForm from "./VerificationForm";

import { Dashboard } from "@mui/icons-material";
import WalletConnect from "./WalletConnect";
import TermsAndCondition from "../Terms&Condition/TermsAndCondition";
function MainContent(props) {
  console.log("🚀10101010", props)
  const { t } = useTranslation();


  return (
    <Container fluid className={`p-3`} style={props?.style}>
      {/* <NewProfile profile={props.profile}/> */}
      <div className={style.left_navigation_fold}>
        <div className={style.main_contant_sec}>
          <section className={style.contant_sec}>
            <div className={style.cntr_main_cnt_sc}>
              <div className={style.dashboard_main_cnt}>
                {
                  // !props.walletAdrsStatus&&(
                    <WalletConnect/>
                  // )
                }

       
               
                {props.profile && (
                  <Profiles
                    profile={props.profile}
                    lodervalue={props.tiles_loading}
                    affiliate_status={props.affiliate_status}
                  />
                )}
                <Tile
                  pvData={props.extra_data}
                  nftInfo={props.nftInfo}
                  SponserData={props.sponser_details}
                  Tiles={props.tiles}
                  filterChange={props.filterChange}
                  lodervalue={props.tiles_loading}
                  affiliate_status={props.affiliate_status}
                  your_wallet={props.your_wallet}
                  nftBalanceTile={props.nftBalanceTile}
                />
                <div>
                  
                </div>
             
                <div className={style.dash_member_join_rank_sec}>
                  <>
                  {props.new_members &&
                    <Members
                      memberList={props.new_members}
                      lodervalue={props.joining_loading}
                    />
                  }
                  {props.joining_graph_data_new &&
                    <Charts
                      data={props.joining_graph_data_new}
                      filterChange={props.chartfilterChange}
                      lodervalue={props.joining_loading}
                    />
                  }

                    {props.rank && <Ranks rank={props.rank} />}
                  </>
                </div>
                <div className={style.dashbord_earning_expence}>
                  {props.team_perfomance && (
                    <TeamPerformance
                      topEarners={
                        props.team_perfomance &&
                        props.team_perfomance.top_earners
                      }
                      topRecruters={
                        props.team_perfomance &&
                        props.team_perfomance.top_recruiters
                      }
                      packageoverviews={
                        props.team_perfomance &&
                        props.team_perfomance.package_overview
                      }
                      rankOverviews={
                        props.team_perfomance &&
                        props.team_perfomance.rank_overview
                      }
                      lodervalue={props.earnings_loading}
                      data={props}
                    />
                  )}

                  {props.earnings_nd_expenses && (
                    <EarningsTab
                      lodervalue={props.earnings_loading}
                      income={
                        props.earnings_nd_expenses &&
                        props.earnings_nd_expenses.incomes
                      }
                      expenses={
                        props.earnings_nd_expenses &&
                        props.earnings_nd_expenses.expenses
                      }
                      payoutstatus={
                        props.earnings_nd_expenses &&
                        props.earnings_nd_expenses.payout_statuses
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

    </Container>
  );
}

export default MainContent;
