import React, { PureComponent } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
//components
import PageTitle from "../../components/common/pageTitle";
import { Content } from "../../components/mailbox";
//service
import MailBoxService from "../../../service/mailbox/mailbox";
import { encode } from "html-entities";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import companyName from "../../../store/mobxStore/companyName";
class MailBox extends PureComponent {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      mailData: [],
      selectedTab: "",
      mailCount: 0,
      showNext: true,
      offset: 0,
      view: false,
      unReadCount: 0,
      messageData: "",
      composeData: "",
      alert: {
        show: false,
        varient: "",
        message: "",
      },
      viewData: {
        id: "",
        thread: "",
        type: "",
      },
      replyData: {
        data: "",
      },
      content: {
        subject: "",
        type: "admin",
        mailContent: "",
        // member: "",
        member: [],
        to: "",
        mail: "",
        tomail: "",
        level: "",
        id: "",
        fromMail: "",
        FormError: {
          subject: "",
          mailContent: "",
          member: "",
          to: "",
          fromMail: "",
          tomail: "",
          level: ""
        },
      },
    };
    this.service = new MailBoxService();
  }

  componentDidMount() {
    this._isMounted = true;
    this.checkurl();
    this.initialiseEditor();
  }
  initialiseEditor = () => {
    const html = "";
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      if (this._isMounted) {
        this.setState({
          editorState,
        });
      }
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.checkurl();
    }
    if (this.state.offset !== prevState.offset) {
      if (
        this.props.match.params.action === "inbox" ||
        Object.values(this.props.match.params).length === 0
      ) {
        this.getInboxMail(this.state.offset, 10);
      } else if (
        this.props.match.params.action === "admin" ||
        Object.values(this.props.match.params).length === 0
      ) {
        this.getInboxMail(this.state.offset, 10);
      } else if (this.props.match.params.action === "send") {
        this.getSendMailList(this.state.offset, 10);
      }
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  //check th eurl fragment
  checkurl = async () => {
    const { action, id, type, thread } = this.props.match.params;
    const { state } = this.props.location;
    if (action && this._isMounted) {
      this.setState({
        //  view: true,
        selectedTab: action,
      });
      if (action === "inbox") {
        if (state) {
          this.setState({
            alert: {
              show: true,
              varient: state.varient,
              message: state.message,
            },
          });
        }
        if (thread && type && id) {
          await this.setState({
            view: true,
            viewData: {
              id: id,
              thread: thread,
              type: type,
            },
          });
          this.ViewMailMessage();
        } else {
          this.getInboxMail();
        }
        // this.getInboxMail();
      }
      if (action === "admin") {
        if (state) {
          this.setState({
            alert: {
              show: true,
              varient: state.varient,
              message: state.message,
            },
          });
        }
        if (thread && type && id) {
          await this.setState({
            view: true,
            viewData: {
              id: id,
              thread: thread,
              type: type,
            },
          });
          this.ViewMailMessage();
        } else {
          this.getAdminMail();
        }
        // this.getInboxMail();
      } else if (action === "send") {
        if (type && id) {
          await this.setState(
            {
              view: true,
              viewData: {
                id: id,
                type: type,
              },
            },
            () => {
              this.ViewMailMessage();
            }
          );
        } else {
          this.getSendMailList();
        }
        // this.getSendMailList();
      } else if (action === "reply") {
        if (id) {
          this.replyMessage(id);
        }
        // this.replyMessage(urls[1]);
      } else if (action === "compose") {
        this.composeMail();
      }
    } else {
      this.getInboxMail();
      this.setState({
        selectedTab: "inbox",
      });
    }
  };

  //get the inbox mail
  getInboxMail = (start = 0, limit = 10) => {
    this.service.inboxMail(start, limit).then((res) => {
      if (res.status) {
        this.setState({
          mailData: res.data.mail_list,
          unReadCount: res.data.unread_count,
          mailCount: res.data.mail_count,

          view: false,
        });
      } else {
        if (res.error.code === 1002) {
          this.props.history.push("/logout");
        } else if (res.error.code === 1057) {
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      }
    });
  };

  //get the admin mail
  getAdminMail = (start = 0, limit = 10) => {
    this.service.adminMail(start, limit).then((res) => {
      if (res.status) {
        this.setState({
          mailData: res.data.mail_list,
          // unReadCount: res.data.unread_count,
          mailCount: res.data.mail_count,

          view: false,
        });
      } else {
        if (res.error.code === 1002) {
          this.props.history.push("/logout");
        } else if (res.error.code === 1057) {
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      }
    });
  };

  //get the send mail list
  getSendMailList = (start = 0, limit = 10) => {
    this.service.sendMail(start, limit).then((res) => {
      if (res.status) {
        this.setState({
          mailData: res.data.mail_list,
          mailCount: res.data.mail_count,
          view: false,
        });
      } else {
        if (res.error.code === 1002) {
          this.props.history.push("/logout");
        } else if (res.error.code === 1057) {
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      }
    });
  };
  //delet the message
  deleteMailInbox = (id, type) => {
    const { t } = this.props;
    const deleteConfirm = window.confirm(
      `${t("validation.sureWantToDelete")} ${t("validation.thereIsNoUndo")}`
    );
    if (deleteConfirm) {
      const data = {
        delete_id: id,
        mail_type: type,
      };
      this.service.deleteMail(data).then((res) => {
        if (res.status) {
          this.refreshList();
          this.setState({
            alert: {
              show: true,
              varient: "success",
              message: "validation.mailDeletedSuccessfully",
            },
          });
        } else {
          if (res.error.code === 1002) {
            this.props.history.push("/logout");
          }
        }
      });
    }
  };

  //view the mail
  viewMail = async (index) => {
    const data = this.state.mailData[index];
    await this.setState({
      view: true,
      viewData: {
        id: data.mail_enc_id,
        thread: data.mail_enc_thread,
        type: data.mail_enc_type,
      },
    });
    this.ViewMailMessage();
    if (this.state.selectedTab === "inbox") {
      this.props.history.replace(
        `/mailbox/inbox/${data.mail_enc_id}/${data.mail_enc_type}/${data.mail_enc_thread}`
      );
    } else if (this.state.selectedTab === "admin") {
      this.props.history.replace(
        `/mailbox/admin/${data.mail_enc_id}/${data.mail_enc_type}/${data.mail_enc_thread}`
      );
    } else {
      this.props.history.replace({
        pathname: `/mailbox/send/${data.mail_enc_id}/${data.mail_enc_type}`,
      });
    }
  };

  //change the tab
  changeTab = (key) => {
    this.setState({
      ...this.state,
      selectedTab: key,
      // view: false,
      // viewData: {
      //   id: "",
      //   type: "",
      //   thread: "",
      // },
      // content: {
      //   subject: "",
      //   type: "admin",
      //   mailContent: "",
      //   member: "",
      //   to: "",
      //   mail: "",
      //   tomail: "",
      //   id: "",
      //   fromMail: "",
      //   FormError: {
      //     subject: "",
      //     mailContent: "",
      //     member: "",
      //     to: "",
      //     fromMail: "",
      //     tomail: "",
      //   },
      // },
      // alert: {
      //   show: false,
      //   varient: "",
      //   message: "",
      // },
    });
    // if(key === 'inbox'){
    //     this.getInboxMail();
    // }else if(key==='send') {
    //     this.getSendMailList()
    // }
  };

  //back button click
  backButton = () => {
    this.setState({
      view: false,
    });
    this.props.history.push(`/mailbox/${this.state.selectedTab}`);
  };

  //refresh list
  refreshList = () => {
    if (this.state.selectedTab === "inbox") {
      this.getInboxMail();
    } else if (this.state.selectedTab === "admin") {
      this.getAdminMail();
    } else {
      this.getSendMailList();
    }
  };

  //compose mail
  composeMail = () => {
    this.setState({
      view: false,
      selectedTab: "compose",
    });
    this.service.getcomposeData().then((res) => {
      if (res.status) {
        this.setState({
          composeData: res.data,
          content: {
            ...this.state.content,
            fromMail: res.data.sender_email,
          },
        });
      } else {
        if (res.error.code === 1002) {
          this.props.history.push("/logout");
        } else if (res.error.code === 1057) {
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      }
    });
  };

  //change handler
  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      content: {
        ...this.state.content,
        [name]: value,
        FormError: {
          ...this.state.content.FormError,
          [name]: "",
        },
      },
    });
  };
  handleSelectchange = (selectedDAta) => {
    console.log('selecteddata', selectedDAta)
    this.setState({
      content: {
        ...this.state.content,
        member: selectedDAta
      }
    })
    console.log('memberrrr', this.state.content.member)
  }
  levelSelecthandler = (level) => {
    
    this.setState({
      content:{
        ...this.state.content,
        level:level.value
      }
    })
   
  }

  //view mail data
  ViewMailMessage = () => {
    const { id, type, thread } = this.state.viewData;
    if (this.state.selectedTab === "inbox") {
      this.service.viewInbox(id, type, thread).then((res) => {
        if (res.status) {
          this.setState({
            ...this.state,
            messageData: res.data,
          });
        } else {
          if (res.error.code === 1002) {
            this.props.history.push("/logout");
          } else if (res.error.code === 1004) {
            this.setState({
              view: false,
              viewData: {
                type: "",
                id: "",
                thread: "",
              },
            });
            this.props.history.push("/mailbox/inbox");
          } else if (res.error.code === 1057) {
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                error: true,
                message: "permissionDenied",
              },
            });
          }
        }
      });
    } else if (this.state.selectedTab === "admin") {
      this.service.viewInbox(id, type, thread).then((res) => {
        if (res.status) {
          this.setState({
            ...this.state,
            messageData: res.data,
          });
        } else {
          if (res.error.code === 1002) {
            this.props.history.push("/logout");
          } else if (res.error.code === 1004) {
            this.setState({
              view: false,
              viewData: {
                type: "",
                id: "",
                thread: "",
              },
            });
            this.props.history.push("/mailbox/admin");
          } else if (res.error.code === 1057) {
            this.props.history.push({
              pathname: "/dashboard",
              state: {
                error: true,
                message: "permissionDenied",
              },
            });
          }
        }
      });
    } else if (this.state.selectedTab === "send") {
      this.service.viewsendbox(id, type).then((res) => {
        if (res.status) {
          this.setState({
            messageData: res.data,
          });
        } else {
          if (res.error.code === 1002) {
            this.props.history.push("/logout");
          } else if (res.error.code === 1004) {
            this.setState({
              view: false,
              viewData: {
                type: "",
                id: "",
                thread: "",
              },
            });
            this.props.history.push("/mailbox/send");
          }
        }
      });
    }
  };

  //reply the message
  replyMessage = (id) => {
    // window.location.hash ="#reply#"+id;
    this.props.history.push(`/mailbox/reply/${id}`);
    this.service.getReplyData(id).then((res) => {
      if (res.status) {
        this.setState({
          content: {
            ...this.state.content,
            toUser: res.data.reply_to_user,
            subject: res.data.reply_msg,
          },
          replyData: res.data,
        });
      } else {
        if (res.error.code === 1002) {
          this.props.history.push("/logout");
        } else if (res.error.code === 1004) {
          this.setState({
            view: false,
            viewData: {
              type: "",
              id: "",
              thread: "",
            },
          });
          // window.location.hash ="#send"
        } else if (res.error.code === 1057) {
          this.props.history.push({
            pathname: "/dashboard",
            state: {
              error: true,
              message: "permissionDenied",
            },
          });
        }
      }
    });
    this.setState({
      view: false,
      selectedTab: "reply",
      content: {
        ...this.state.content,
        id: id,
      },
    });
  };

  htmlDecode = (input) => {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes.length === 0
      ? ""
      : e.childNodes[0].nodeValue
        ? e.childNodes[0].nodeValue.length
        : 0;
  };
  input1 = "nbsp;";
  input2 = "nbsp;nbsp;nbsp;nbsp;";
  input3 = "nbsp;nbsp; HELLO WORLD nbsp;";

  allSpaces = (str) => {
    let arr = str.trim().split(";");
    arr = arr.slice(0, arr.length - 1);
    return arr.every((str) => str === "nbsp");
  };

  //compose message send
  sendMessage = () => {

    let isValid = true;
    let formData = this.state.content;
    if (!formData.subject) {
      isValid = false;
      formData.FormError.subject = "validation.required";
    }
    if (this.state.selectedTab === "compose") {
      if (!formData.type) {
        isValid = false;
        formData.FormError.type = "validation.required";
      }
    }
    if (
      !formData.mailContent
    ) {
      isValid = false;
      formData.FormError.mailContent = "validation.required";
    }
    // if (formData.type === "individual" && !formData.member) {
    //   isValid = false;
    //   formData.FormError.member = "validation.required";
    // } 
    if (formData.type === "individual" && formData.member.length === 0) {

      isValid = false;
      formData.FormError.member = "validation.required";
      console.log('formdataaa', formData.FormError.member)
    } else if (formData.type === "level" && !formData.level) {
      isValid = false;
      formData.FormError.level = "validation.required";
    }
    else if (formData.type === "externalMail" && !formData.tomail) {
      isValid = false;
      formData.FormError.tomail = "validation.required";
    }
    // return false
    if (isValid) {
      let messageData = {
        subject: formData.subject,
        message: encode(formData.mailContent),
        // type    : formData.type,
      };
      if (this.state.selectedTab === "compose") {
        messageData.type = formData.type;
      } else if (this.state.selectedTab === "reply") {
        messageData.mail_id = formData.id;
      }
      if (formData.type === "individual") {
        messageData.user = formData.member;
      }if(formData.type==="level"){
        messageData.level=formData.level
      } else if (formData.type === "externalMail") {
        messageData.ext_mail_from = formData.fromMail;
        messageData.ext_mail_to = formData.tomail;
      }
      if (this.state.selectedTab === "compose") {
        this.service.composeData(messageData).then((res) => {
          if (res.status) {
            this.initialiseEditor();
            this.setState({
              alert: {
                show: true,
                varient: "success",
                message: "validation.mailSendSuccessfully",
              },
              content: {
                subject: "",
                type: "admin",
                mailContent: "",
                member: "",
                to: "",
                mail: "",
                tomail: "",
                id: "",
                FormError: {
                  subject: "",
                  mailContent: "",
                  member: "",
                  to: "",
                  tomail: "",
                },
              },
            });
          } else {
            if (res.error.code === 1002) {
              this.props.history.push("/");
            } else {
              this.setState({
                alert: {
                  show: true,
                  varient: "danger",
                  message: "validation.mailSendFailed",
                },
              });
            }
          }
        });
      } else {
        this.service.mailReply(messageData).then((res) => {
          if (res.status) {
            this.initialiseEditor();
            this.setState({
              alert: {
                show: true,
                varient: "success",
                message: "validation.mailSendSuccessfully",
              },
              content: {
                subject: "",
                type: "admin",
                mailContent: "",
                member: "",
                to: "",
                mail: "",
                tomail: "",
                id: "",
                FormError: {
                  subject: "",
                  mailContent: "",
                  member: "",
                  to: "",
                  tomail: "",
                },
              },
            });
            this.props.history.push({
              pathname: "/mailbox/inbox",
              state: {
                varient: "success",
                message: "validation.mailSendSuccessfully",
              },
            });
          } else {
            if (res.error.code === 1002) {
              this.history.push("/logout");
            }
          }
        });
      }
    } else {
      this.setState({
        formData,
        alert: {
          show: true,
          varient: "danger",
          message: "validation.checkValueYouHaveSubmitted",
        },
      });
    }
  };

  //texteditor change handler
  editorChange = (editorState) => {
    const data = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let content = this.state.content;
    content.mailContent = data;
    this.setState((prev) => ({
      ...prev,
      content,
      editorState,
    }));
  };

  closeAlert = () => {
    this.setState({
      alert: {
        show: false,
        varient: "",
        message: "",
      },
    });
  };

  handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * 10);
    let showNext = true;
    if (offset > 0) {
      showNext = Math.floor(this.state.mailCount / offset) === 1 ? false : true;
    }
    this.setState((prev) => ({
      ...prev,
      offset: offset,
      showNext: showNext,
    }));
  };

  render() {
    const { t } = this.props;
    return (
      <div className={`h-100`}>
        <Helmet>
          <title>
            {companyName.companyName} | {t("Sidemenu.mailbox")}
          </title>
        </Helmet>
        <PageTitle title="mailbox" buttonOn={false} />
        <Content
          {...this.state}
          handlePageClick={this.handlePageClick}
          deleteMail={this.deleteMailInbox}
          viewMail={this.viewMail}
          changeTab={this.changeTab}
          backButtonClick={this.backButton}
          refersh={this.refreshList}
          changeHandler={this.changeHandler}
          replyMessage={this.replyMessage}
          messagesend={this.sendMessage}
          editorChange={this.editorChange}
          close={this.closeAlert}
          handleSelectchange={this.handleSelectchange}
          levelSelecthandler={this.levelSelecthandler}
        />
      </div>
    );
  }
}

export default withTranslation()(withRouter(MailBox));
