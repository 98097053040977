import React from 'react'
import { Card } from 'react-bootstrap'
import AddForm from './AddForm'
import { AlertMessage } from '../common'
import { useState } from 'react'
import Swal from "sweetalert2";
import { Modal } from 'react-bootstrap'
import style from '../../components/profile/profile.module.scss'
import { useTranslation } from 'react-i18next'
function WalletConnect() {
  const {t}=useTranslation();
  const [alertMsg, setalertMsg] = useState({
    show: false,
    type: "",
    message: ""
  })

  
  const getErrormsg = (type, msg) => {
    Swal.fire({
      title: msg,
      icon: type,
      confirmButtonText: "OK",
    })

  }

  console.log("alert", alertMsg.show)
  const dismiss = () => {
    setalertMsg((prev) => ({
      ...prev,
      show: false,
      type: "",
      message: ""
    }))
  }

 
  return (
    <div>
     
     

      <AddForm
        getErrormsg={getErrormsg} />
    </div>



  )
}

export default WalletConnect