import React from 'react'
import { Card, Row, Col, Badge } from 'react-bootstrap'
import style from "./nft.module.scss";
import { useTranslation } from 'react-i18next';
import nodata from "../../../assets/images/nophoto/no-datas-found.png"
function Gallery(props) {
    console.log("gallery", props)
    const { t } = useTranslation();
    return (
        <div className='m-3'>
            <Card>
                <center>
                    <Card.Header className={style.galHeading}>
                        <h2>
                        <strong>{t('Common.nftgallery').toLocaleUpperCase()}</strong>
                        </h2>
                       
                    </Card.Header>
                </center>

                <Card.Body style={{background:'#dcdcdc70'}}>
                    <Row>
                        {
                            props.data.length!=0?                           
                                props.data.map((item) => {
                                    return (
                                        <Col lg={4} md={3} sm={1} className='p-1'>
                                            <center><Card.Img src={item.image ? item.image : 'https://i.insider.com/6123e0324932030018457fa3?width=700'}  style={{}} className='w-75'/></center>
    
                                            <center>
                                                <Card.Text className={style.gallery_title}>{item.name}</Card.Text>
                                                {
                                                    item.is_staked && (
                                                        <span 
                                                        // style={{color:'#2BB012',fontWeight:"bolder"}}
                                                        style={{fontWeight:'bolder',color:'#0f0',textShadow:'0px 2px 5px rgba(181, 141, 141, 0.8)',padding:'10px',borderRadius:'5px'}}
                                                        >STAKED</span>
                                                    )
                                                }
                                            </center>
                                        </Col>
                                    )
                                })
                            
                            :<div>
                                <center><img src={nodata} style={{width:'50%'}}/></center>
                            </div>
                        }
                       


                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Gallery