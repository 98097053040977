const Affiliate_Status=true
const AffiliateStsReducer=(state=Affiliate_Status,action)=>{
    switch (action.type) {
        case 'Affiliate_Status':
            return action.affiliateStatus
         
    
        default:
            return state;
    }
}

export default AffiliateStsReducer;