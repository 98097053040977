import React, { useState,useEffect } from 'react';
import IdleTimeOutHandlerCom from '../../components/autologout/IdleTimeOutHandler';


const IdleTimeOutHandler = (props) => {
console.log("idletime",props)
    const[isActive,setIsActive]=useState(true)
    const[isLogout,setLogout]=useState(false)


return (

    <IdleTimeOutHandlerCom 
    {...props}
    onActive={()=>{setIsActive(true)}} 
    onIdle={()=>{setIsActive(false)}}
    onLogout={()=>{setLogout(true)}}
    />


)



}
export default IdleTimeOutHandler;