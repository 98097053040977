const currentstatus = false;
const LoggedReducer = (state = currentstatus, action)=>{
     switch (action.type) {
       
         case 'SIGN_IN':
             return true
         case  'LOG_OUT':
             return false;
         default:
             return state;
     }
 }

 export default LoggedReducer;