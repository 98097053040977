import { BASE_URL } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";

export default class DownLineService {
  key = localStorage.getItem("apiKey");
  //get the downline members
  async getDownline(level = "all", offset = 0, limit = 20) {
    const response = await fetch(
      BASE_URL +
        "tree/downline_members_new?level=" +
        level +
        "&offset=" +
        Number(offset) * Number(limit) +
        "&limit=" +
        limit,
      {
        //url
        method: "GET", //method
        headers: {
          //passing header
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    const legStatus = await response.json();
    return legStatus;
  }

  async getDownlineCount(level = "all") {
    const response = await fetch(
      BASE_URL + "tree/downline_members_count?level=" + level,
      {
        //url
        method: "GET", //method
        headers: {
          //passing header
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": this.key,
          "access-token": UserStore.key,
        },
      }
    );
    const result = await response.json();
    return result;
  }
}
