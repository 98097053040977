import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { IdleTimeOutModal } from './IdleTimeOutModal'
import { useHistory } from 'react-router-dom'
import { useIdleTimer } from "react-idle-timer";
import { useRef } from 'react';
const IdleTimeOutHandlerCom = (props) => {
    console.log("idletimehandler", props.time)
    const history = useHistory();
    const idleTimeRef = useRef(null);


    const onIdle = () => {
        history.push('/timeout')
    };
    const idleTimer = useIdleTimer({
        crossTab: true,
        ref: idleTimeRef,
        timeout: props.time,
        onIdle: onIdle,
    });

    // console.log("timtmtmt",props)
    // const[showModal,setShowModal]=useState(false)
    // const[isLogout,setLogout]=useState(false)
    // let timer=undefined;
    // const history = useHistory();
    // const events= ['click','load','keydown','mousemove']
    // console.log('isLogout',isLogout)
    // const eventHandler =(eventType)=>{
    //     if(!isLogout){
    //         console.log("islogedEneterd==")
    //         localStorage.setItem('lastInteractionTime',moment() )
    //         if(timer){
    //             props.onActive();
    //             startTimer();
    //         }
    //     }

    // };

    // useEffect(()=>{
    //     addEvents();

    //     return (()=>{

    //         removeEvents();
    //         clearTimeout(timer);
    //     })
    // },[])

    // const startTimer=()=>{
    //     console.log("entered-===")
    //     if(timer){
    //         clearTimeout(timer)
    //     }
    //     timer=setTimeout(()=>{           
    //         let lastInteractionTime=localStorage.getItem('lastInteractionTime')
    //         const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
    //         console.log("diffffffff",diff)
    //         let timeOutInterval=props.time?props.time:60000000;
    //         if(isLogout){

    //             clearTimeout(timer)
    //         }else{
    //             if(diff._milliseconds<timeOutInterval){
    //                 startTimer();
    //                 props.onActive();
    //             }else{
    //                 props.onIdle();
    //                 // setShowModal(true)

    //                 localStorage.setItem("timeout", true)

    //                 const timeoutStatus=localStorage.getItem("timeout")

    //                 if(timeoutStatus=='true'){
    //                     history.push('/timeout')
    //                     clearTimeout(timer)
    //                 }                  

    //                 // setLogout(true)

    //                 //  history.push('/logout')
    //             }
    //         }

    //     },props.timeOutInterval?props.timeOutInterval:6000)





    // }
    // const addEvents=()=>{

    //     events.forEach(eventName=>{
    //         window.addEventListener(eventName,eventHandler)
    //     })

    //     startTimer();
    // }

    // const removeEvents=()=>{
    //     events.forEach(eventName=>{
    //         window.removeEventListener(eventName,eventHandler)
    //     })
    // };

    // const handleContinueSession = ()=>{
    //     setShowModal(false)
    //     setLogout(false)
    // }
    // const handleLogout = ()=>{
    //     removeEvents();
    //     clearTimeout(timer);
    //     setLogout(true)
    //     props.onLogout();
    //     setShowModal(false)

    // }

    return (
        <div idleTimer={idleTimer}>
            {/* <IdleTimeOutModal 
        showModal={showModal} 
        handleContinue={handleContinueSession}
        handleLogout={handleLogout}
        /> */}

        </div>

    )

}

export default IdleTimeOutHandlerCom;