import React from "react";
import style from "./dashboard.module.scss";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
function Links({ iscopy, icon, link, code }) {
  console.log("code ====== ",code);
  const { t } = useTranslation();
  const { currentTheme } = useSelector((state) => state.Theme);
  function shareButton(url) {
    window.open(url, "_blank", "height=450,width=550");
  }
  const popover = (
    <Popover id="popover-basic" varient="success">
      <Popover.Title as="h3">{t("Dashboard.linkCopied")}</Popover.Title>
    </Popover>
  );
  function Clipboard_CopyTo(value) {
    var tempInput = document.createElement("input");
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }

  return (
    <>
      {iscopy ? (
        <div
          className={
            currentTheme === "theme-dark"
              ? style.darkMode_referralLink
              : style.referralLInk
          }
        >
          {code == "replica" && (
            <Form.Control
              disabled={true}
              type="text"
              value={link}
              style={{ borderRadius: "1em" }}
            />
          )}
          <OverlayTrigger
            trigger="click"
            placement="right"
            overlay={popover}
            rootClose
          >
            <a onClick={() => Clipboard_CopyTo(link)}>
              <div
                className={
                  currentTheme === "theme-dark"
                    ? style.Darkmode_profilesoc_share_bx_ico
                    : style.profilesoc_share_bx_ico
                }
              >
                <i className={icon}></i>
              </div>
            </a>
            {/* <Button onClick={()=>Clipboard_CopyTo(link)} className={style.profilesoc_share_bx_ico}><i className={icon}></i></Button> */}
          </OverlayTrigger>
        </div>
      ) : (
        <>
          <a
            onClick={() => shareButton(link)}
            className={style.profilesoc_share_bx_ico}
          >
            <i className={icon}></i>
          </a>
        </>
      )}
    </>
  );
}

export default Links;
