import React from 'react';
import Helmet from 'react-helmet';
import companyName from '../../../store/mobxStore/companyName';
import PageTitle from '../../components/common/pageTitle';
import Content from '../../components/Announcement/Content';
import { useTranslation } from 'react-i18next';
function Announcement() {
    const {t}=useTranslation();
    return (
        <div className="h-100">
            <Helmet>
                <title>{companyName.companyName} | {t('Sidemenu.announcement')}</title>
            </Helmet>
            <PageTitle
                title="announcement"
                buttonOn={false}
            />
            <Content />

        </div>
    )
}

export default Announcement