import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button, Alert, Spinner, Card } from "react-bootstrap";
import api from "../../../api/api";
import { PageTitle } from "../../components/common";
import become_affiliate from "../../components/dashboard/become_affiliate.jpg"
import "./button.css"
import { Helmet } from "react-helmet";
import companyName from "../../../store/mobxStore/companyName";
import { useTranslation } from "react-i18next";
import AppInfoservice from "../../../service/common/Appinfo";
import { AffiliateSatus, addMenus } from "../../../store/action";
import { useDispatch } from "react-redux";
import Checkboxstyle from "../../components/registration/registration.module.scss";
import TAndC from "../../components/registration/TAndC"
import RegisterService from "../../../service/Auth/Register";
import { AlertMessage } from "../../components/common";
function TermsAndConditions(props) {
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const service = new RegisterService();
    const [data, setmodal] = useState({
        show: false,
        content: ""
    })
    const [notify, setnotify] = useState({
        show: false,
        message: '',
        varient: ''
    })
    const [btnColor, setbtnColor] = useState(false)

    const [isAccepted, setisAccepted] = useState(true)
    const handleAgreeClick = async () => {
        if (isAccepted == false || btnColor == false) {
            console.log("=====entered")
            setnotify((prev) => ({
                ...prev,
                show: true,
                message: t('Common.acceptT&C'),
                varient: 'danger'
            }))
        } else {
            setShowSpinner(true); // Show spinner when the button is clicked
            try {
                const response = await api.API.post("home/update-affiliate-terms-and-conditions");
                if (response.status === 200) {
                    setShowSuccess(true);
                    affiliateStatus();
                    setTimeout(() => {
                        setShowSuccess(false);
                        history.push("/dashboard");
                    }, 3000);

                }
            } catch (error) {
                console.log(error);
            } finally {
                setShowSpinner(false); // Hide spinner when the API call is complete
            }
        }

    };

    const affiliateStatus = () => {
        const service = new AppInfoservice();
        service.get_layout().then((res) => {
            if (res.status) {
                dispatch(AffiliateSatus(res.data.affiliate))
                dispatch(addMenus(res.data.menu_list))
            }
        })
    }

    const handleClose = () => {
        setmodal((prev) => ({
            ...prev,
            show: false
        }))
        // setisAccepted(false)
    }

    const openModal = () => {
        service.getBecomeAffiliateTAndC().then((res) => {
            if (res.status) {
                setmodal((prev) => ({
                    ...prev,
                    show: true,
                    content: res.data
                }))
            }
        })
    }

    const checkBoxhandler = (e) => {
        const { name, value, checked } = e.target
        if (checked) {
            setbtnColor(true)
            setisAccepted(true)
        } else {
            setbtnColor(false)
            setisAccepted(false)
        }
    }

    const handleDismiss = () => {
        setnotify((prev) => ({
            ...prev,
            show: false,
            message: '',
            varient: ''
        }))
    }
    return (
        <>
            {
                notify.show && (
                    <AlertMessage
                        show={notify.show}
                        message={notify.message}
                        type={notify.varient}
                        dismiss={handleDismiss}
                    />
                )
            }

            <Container>
                <Helmet>
                    <title>
                        {companyName.companyName} | {t("Sidemenu.mailbox")}
                    </title>
                </Helmet>

                <TAndC
                    data={data}
                    handleClose={handleClose}
                />


                <Row>
                    <Col>

                        <Card style={{ borderRadius: "40px" }} >
                            <Card.Body>

                                <Card.Title><strong><h3 style={{ color: 'black', fontWeight: "bold" }}>Become an Affiliate</h3></strong></Card.Title>
                                <Card.Img src={become_affiliate} ></Card.Img>
                                <br />
                                <br />

                                <Card.Text>
                                    <small>
                                        At Carbon War, our team is dedicated to democratizing participation in the carbon markets while making a meaningful difference for our planet. Through our Web3.0 platform, we empower individuals like you to actively contribute to reforestation efforts and the global fight against carbon emissions.
                                    </small>
                                </Card.Text>
                                <br />
                                <Card.Text>
                                    <small>
                                        With our Carbon War NFTs, you have the opportunity to directly fund tree planting initiatives and play a crucial role in the restoration of our environment. We firmly believe in the importance of trees, as they provide us with oxygen and effectively remove harmful carbon dioxide from the atmosphere.
                                    </small>
                                </Card.Text>
                                <br />
                                <Card.Text>
                                    <small>
                                        Your generous contribution towards tree planting helps to generate new, high quality, ISO-14064-2 certified, Carbon Certificates.  These Certificates are high in demand and high value.  We use Web3.0 technology as a tool to track your participation and to provide you with tokenized Carbon Certificates (CWAR tokens) that are exchangeable for Carbon Certificates or stable coins.  These Carbon Certificates recognize your contribution and demonstrate your commitment to the cause. We encourage you to explore the Carbon Markets, as they offer both environmental benefits and the potential for financial rewards.

                                    </small>
                                </Card.Text>
                                <br />
                                <Card.Text>
                                    <small>
                                        By joining our global Affiliate team, you can enjoy an exciting opportunity to earn referral commissions and establish a sustainable, passive income stream. As the value of carbon certificates continues to rise, you can not only contribute to a greener future but also benefit financially from the efforts of your involvement.
                                    </small>
                                </Card.Text>
                                <br />
                                <Card.Text>
                                    <small>
                                        At Carbon War, our aim is to provide you with an intuitive platform that simplifies and rewards your efforts in making a positive impact on our planet. Together, let's harness the power of technology, environmental consciousness, and financial incentives to build a community committed to achieving carbon emission neutrality and creating a better world for generations to come.
                                    </small>
                                </Card.Text>
                                <br />
                                <div className={Checkboxstyle.checkbox}>

                                    <input
                                        type="checkbox"
                                        name='affiliate'
                                        className={Checkboxstyle.checkboxInput}
                                        onChange={checkBoxhandler}
                                        // value={props.data.value}
                                        // defaultChecked={props.data.value}
                                        // ref={el => (this.selector = el)}
                                        id="terms"
                                    // {...inputProps}
                                    />
                                    <label
                                        className={`${Checkboxstyle.checkboxLabel}`}
                                        onClick={openModal}
                                    >
                                        {t("Common.acceptTermsAndCondietion")}<sup style={{ color: 'red' }}>*</sup>
                                    </label>

                                </div>
                                {
                                    isAccepted == false && (
                                        <div style={{ marginTop: '-14px' }}><small style={{ color: '#a94442', marginLeft: '3em' }}>{t('Common.acceptT&C')}</small></div>

                                    )
                                }
                                {showSuccess && (
                                    <Alert variant="success" className="mt-3">
                                        Registered Successfully
                                    </Alert>
                                )}
                                <div className="button">
                                    <button onClick={handleAgreeClick} disabled={showSpinner || showSuccess} style={{ background: btnColor ? 'rgb(31, 119, 160)' : 'grey' }}>
                                        {showSpinner ? (
                                            <>
                                                <Spinner animation="border" size="sm" className="mr-2" />

                                            </>
                                        ) : (
                                            "Register"
                                        )}
                                    </button>
                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container></>

    );

}

export default TermsAndConditions;
