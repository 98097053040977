import React, { useEffect, useState } from "react";
import EwalletService from "../../../service/ewallet/Ewallet";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Logo from '../../../assets/images/logos/Carbon War Horiztonal Logo.png';
//service
import AppInfoservice from "../../../service/common/Appinfo";

//style
import { Row, Col } from "react-bootstrap";

export class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);
    this.service = new EwalletService();

    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()} , ${current.getHours()}:${current.getMinutes()}`;
    // const time = current.getHours() + ':' + current.getMinutes();

    this.state = {
      earnings: {},
      loading: true,
      date: date,
    };
  }

  exportDatas(type) {
    let keys = this.props.data.filterValues
      .map((key, idx) => {
        return "categories[" + idx + "]=" + key.value;
      })
      .join("&");
    this.service
      .exportData(
        this.props.data.startDate,
        this.props.data.endDate,
        keys,
        type
      )
      .then((res) => {
        if (res.status) {
          this.setState((prevState) => ({
            ...prevState,
            loading: false,
            earnings: res.data,
          }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            loading: true,
          }));
        }
      });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.data.filterValues != prevProps.data.filterValues ||
      this.props.data.startDate != prevProps.data.startDate ||
      this.props.data.endDate != prevProps.data.endDate
    ) {
      this.exportDatas("print");
    }
  }
  componentDidMount() {
    this.exportDatas("print");
  }

  render() {
    return (
      <div style={{ padding: 60 }}>
        <Row>
          <Col>{this.state.date}</Col>
          <Col>
            <h5 style={{ textAlign: "center" }}>User Earnigs Report</h5>
          </Col>
        </Row>

        <div ref={this.ref}>
          <Row style={{ marginBottom: 50 }}>
            <Col span={8}>
              {/* <img src={this.props.company.logo} alt="Logo" /> */}
              <img src={Logo} alt="Logo" style={{width:'200px'}} />
              <div>COMPANY NAME : {this.props.company.comapny_name}</div>
              <div>COMPANY ADDRESS : {this.props.company.company_address}</div>
              <div>TELEPHONE : {this.props.company.phone}</div>
              <div>EMAIL : {this.props.company.email}</div>
            </Col>
          </Row>

          {!this.state.loading && this.state.earnings.length > 0 && (
            <Table striped hover>
              <thead>
                <tr>
                  <th>{this.props.t("Common.category")}</th>
                  <th>{this.props.t("Common.totalAmount")}</th>
                  <th>{this.props.t("Common.tax")}</th>
                  <th>{this.props.t("Common.serviceChange")}</th>
                  <th>{this.props.t("Common.amountPayable")}</th>
                  <th>{this.props.t("Common.transactionDate")}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.earnings.map((obj, index) => {
                  return (
                    <tr key={index}>
                      
                      <td>{this.props.t("ewallet."+obj.category)}</td>
                      <td>
                        {" "}
                        {this.props.Currency.currentCurr}
                        {(
                          parseFloat(obj.total_amount) *
                          this.props.Currency.value
                        ).toFixed(this.props.Currency.precision)}
                      </td>
                      <td>
                        {this.props.Currency.currentCurr}
                        {(
                          parseFloat(obj.tax) * this.props.Currency.value
                        ).toFixed(this.props.Currency.precision)}
                      </td>
                      <td>
                        {this.props.Currency.currentCurr}
                        {(
                          parseFloat(obj.service_charge) *
                          this.props.Currency.value
                        ).toFixed(this.props.Currency.precision)}
                      </td>
                      <td>
                        {this.props.Currency.currentCurr}
                        {(
                          parseFloat(obj.amount_payable) *
                          this.props.Currency.value
                        ).toFixed(this.props.Currency.precision)}
                      </td>
                      <td>{obj.transaction_date}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    );
  }
}

const UserEarningsPrintView = React.forwardRef((props, ref) => {
  const Currency = useSelector((state) => state.curr);
  const { t } = useTranslation();

  const [companyDetails, setCompanyDetails] = useState({});

  const service = new AppInfoservice();

  useEffect(() => {
    service.Appinfo().then((res) => {
      if (res.status) {
        setCompanyDetails((prevState) => ({
          ...prevState,
          logo: res.data.company_info.login_logo,
          comapny_name: res.data.company_info.company_name,
          company_address: res.data.company_info.company_address,
          phone: res.data.company_info.phone,
          email: res.data.company_info.email,
        }));
      }
    });
  }, []);
  return (
    <ComponentToPrint
      ref={ref}
      data={props}
      company={companyDetails}
      Currency={Currency}
      t={t}
    />
  );
});
export default UserEarningsPrintView;
