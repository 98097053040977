import React from 'react';
import { Card, Modal } from 'react-bootstrap';
//import style from './crm.module.scss';
import style from '../crm/crm.module.scss';
import Button from 'react-bootstrap/Button';
const DashboardModel = ({ children, ...props }) => {
    return (
        <Modal show={props.show} 
        // onHide={props.close} 
        backdrop="static">
            <Modal.Header className={`${style.ModalHeader}`}>
                <Modal.Title className={`text-center`}>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body 
            // className={`p-0`}  
            className={style.ModalContent}
            >              
                {children}                 
            </Modal.Body>

        </Modal>
    );
}

export default DashboardModel;