import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import style from "./nft.module.scss";
import { CurrencyDecimalFormat } from "../../helper";
function Tiles(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const Currency = useSelector((state) => state.curr);
  useEffect(() => {
    let ewalletTiles = props.tilesData.map((items) => {
      console.log("itemssss", items)
      var background;
      var img;
      switch (items.text) {
        case "totalNFT":
          background = style.green;
          img = "income-w.png";
          break;
        case "nftStake":
          background = style.red;
          img = "income-w.png";
          break;
        case "ewalletBalance":
          img = "E-Wallet-w.png";
          background = style.violet;
          break;
        case "nftEarnings":
          img = "E-Wallet-w.png";
          background = style.pink;
          break;
        case "stakeEarnings":
          img = "wallet-icon.png";
          background = style.blue;
          break;
        default:
          background = style.blue;
          img = "income-w.png";
      }
      // background = style.blue
      if (items.amount) {
        return {
          amount: items.amount,
          text: items.text,
          background: background,
          img: img
        }
      }
      return {
        amount: items.amount,
        text: items.text,
        count: items.count,
        background: background,
        img: img,
        annualRewards:items.annualRewards
      };
    });
    setData(ewalletTiles);
  }, [setData, props.tilesData]);

  console.log("currency", Currency.currentCurr)
  return (
    <div className={`${style.ewalletTiles}`}>
      {data.map((tiles, index) => {
        console.log("tilessss", tiles)
        return (
          <div
            className={`${currentTheme == "theme-dark"
              ? `${style.dark_mode_Tiles} d-flex`
              : `${style.Tiles} d-flex`
              }`}
            key={index}
          >
            <div className={`${style.leftIcon} ${tiles.background}`}>
              <img
                src={require("../../../assets/images/logos/" + tiles.img).default
                }
                alt=""
              />
            </div>
            <div
              className={`${currentTheme == "theme-dark"
                ? `${style.dark_mode_tilesData}`
                : `${style.tilesData}`
                }`}
            >
              <h4>{t("Common." + tiles.text)}</h4>
                {
                  tiles.text==="stakeEarnings"?
                  <span>{tiles.annualRewards}</span>
                  :<span>{tiles.count}</span>
                }
              {/* // {
              //   tiles.count && (
              //     <span>{tiles.count}</span>
              //   )
              // } */}

            </div>
          </div>
        );
      })}
    </div>
  )
}

export default Tiles