import React, { useEffect } from 'react'
import { Row, ListGroup, Tab, Col, Spinner } from 'react-bootstrap'
import { useState } from 'react';
import DashboardService from '../../../service/dashboard/Dashboard';
import { useTranslation } from 'react-i18next';
import style from '../../components/registration/registration.module.scss'
import AppInfoservice from '../../../service/common/Appinfo';
import { useHistory } from "react-router-dom";
import { AlertBs } from '../common';
import { styleTagSSRMarker } from 'framer/build/modules/withCSS';
function TermsAndCondition(props) {
    const [activeKey, setActiveKey] = useState('user');
    const { t } = useTranslation();
    const history=useHistory();
    const handleTabClick = (eventKey) => {
        setActiveKey(eventKey);
        setstate((prev) => ({
            ...prev,
            CheckboxStatus: true
        }))
    };
    const [state, setstate] = useState({
        tab: [],
        Content: [],
        CheckboxStatus: false,
        checkedType: '',
        affiliate: false,
        user: false,
        affiliateFinished:false,
        userFInished:false,
        alert:{
            varient:'',
            message:'',
            show:false
        }
    })
    const service = new DashboardService();
    const [loader, setloader] = useState(true)
    useEffect(() => {
        service.getTermsAndCondion().then((res) => {
            setloader(true)
            if (res.status) {
                setloader(false)
                setstate((prev) => ({
                    ...prev,
                    tab: res.data.tab,
                    Content: res.data.content,
                    CheckboxStatus: true
                }))
            }
        })
    }, [])
    const checkConditionhandler = (e, type) => {
        const { checked } = e.target
        if (checked) {
            setstate((prev) => ({
                ...prev,
                checkedType: type,
                affiliate: true
            }))
            let formdata = {
                status: checked
            }
            service.postAffiliateConditionstatus(formdata).then((res)=>{
                if(res.status){
                    setstate((prev)=>({
                        ...prev,
                        affiliateFinished:true
                    }))
                }else{
                    setstate((prev)=>({
                        ...prev,
                        alert:{
                            ...prev.alert,
                            show:true,
                            varient:'danger',
                            message:t('Common.error')
                        }
                    }))
                }
            })
        }
    }
    const checkUserConditionhandler = (e, type) => {
        console.log("entered======")
        const { checked } = e.target
        if (checked) {
            setstate((prev) => ({
                ...prev,
                checkedType: type,
                user: true
            }))
            let formdata = {
                // ConditionTYpe: type,
                status: checked
            }
            service.postConditionstatus(formdata).then((res)=>{
                if(res.status){
                    setstate((prev)=>({
                        ...prev,
                        userFInished:true
                    }))
                }else{
                    setstate((prev)=>({
                        ...prev,
                        alert:{
                            ...prev.alert,
                            show:true,
                            varient:'danger',
                            message:t('Common.error')
                        }
                    }))
                }
            })
        }
    }


    useEffect(()=>{
        if(state.tab.length==1){
            if(state.userFInished){
                const service=new AppInfoservice();
                service.get_layout().then((res)=>{
                    if(res.status){
                        if(res.data.terms_status){
                            console.log("enterd===",res.data.terms_status)
                            props.modalclose();
                            history.push('/dashboard')
                        }
                    }else{
                        setstate((prev)=>({
                            ...prev,
                            alert:{
                                ...prev.alert,
                                show:true,
                                varient:'danger',
                                message:t('Common.error')
                            }
                        }))
                    }
                })
            }
        }else{
            if(state.userFInished && state.affiliateFinished){
                const service=new AppInfoservice();
                service.get_layout().then((res)=>{
                    if(res.status){
                        if(res.data.terms_status){
                            console.log("enterd===",res.data.terms_status)
                            props.modalclose();
                            history.push('/dashboard')
                        }
                    }else{
                        setstate((prev)=>({
                            ...prev,
                            alert:{
                                ...prev.alert,
                                show:true,
                                varient:'danger',
                                message:t('Common.error')
                            }
                        }))
                    }
                })
            } 
        }
        
    },[state])
    console.log("loader", loader)

    const CloseAlert=()=>{
        setstate((prev)=>({
            ...prev,
            alert:{
                ...prev.alert,
                show:false,
                varient:'',
                message:''
            }
        }))
    }
    return (
        <>
        {
            state.alert.show&&(
                <AlertBs {...state.alert} close={CloseAlert}/>
            )
        }
            {
                loader && (
                    <center><Spinner animation="grow" /></center>
                )

            }
            <Tab.Container id="list-group-tabs-example" activeKey={activeKey} onSelect={handleTabClick}>
                <Row>

                    <Col sm={4}>
                        <ListGroup>
                            {
                                state.tab.map((item, index) => {
                                    return (
                                        <ListGroup.Item eventKey={item.eventKEY} onClick={() => handleTabClick('affiliate')}>

                                            {t(`Common.${item.text}`)}
                                        </ListGroup.Item>
                                    )


                                })
                            }

                        </ListGroup>
                    </Col>
                    <Col sm={8}>
                        <Tab.Content>
                            {
                                state.Content.map((items, index) => {
                                    return (
                                        <Tab.Pane eventKey={items.eventKEY}>
                                            <div style={{ border: '3px solid #0000001a', padding: '10px' }}>
                                                {items.content}
                                            </div>


                                        </Tab.Pane>
                                    )
                                })
                            }
                            {
                                state.CheckboxStatus && (
                                    <>
                                        {
                                            activeKey === "affiliate" ?
                                                <div className={style.checkbox}>
                                                    <input
                                                        type="checkbox"
                                                        // name={props.data.code}
                                                        checked={state.affiliate}
                                                        className={style.checkboxInput}
                                                        onChange={(e) => checkConditionhandler(e, activeKey)}

                                                    // value={props.data.value}
                                                    // defaultChecked={props.data.value}
                                                    // // ref={el => (this.selector = el)}
                                                    // id="terms"
                                                    // {...inputProps}
                                                    />
                                                    <label
                                                        className={style.checkboxLabel}
                                                    >
                                                        {t("Common.acceptTermsAndCondietion")}
                                                    </label>
                                                </div>
                                                : <div className={style.checkbox}>
                                                    <input
                                                        type="checkbox"
                                                        // name={props.data.code}
                                                        checked={state.user}
                                                        className={style.checkboxInput}
                                                        onChange={(e) => checkUserConditionhandler(e, activeKey)}

                                                    // value={props.data.value}
                                                    // defaultChecked={props.data.value}
                                                    // // ref={el => (this.selector = el)}
                                                    // id="terms"
                                                    // {...inputProps}
                                                    />
                                                    <label
                                                        className={style.checkboxLabel}
                                                    >
                                                        {t("Common.acceptTermsAndCondietion")}
                                                    </label>
                                                </div>
                                        }</>


                                )
                            }


                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>


    )
}

export default TermsAndCondition