import React, { useEffect, useState } from "react";
import style from "./dashboard.module.scss";
import wallet from "../../../assets/images/dashboard/wallet-icon.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CurrencyDecimalFormat } from "../../helper";
import commission from "../../../assets/images/dashboard/commision-earned.png";
import commission1 from "../../../assets/images/dashboard/USDC Icon.png";
import payment from "../../../assets/images/dashboard/USDT Icon.png";
import pending from "../../../assets/images/dashboard/pending-5-861794.png";
import matic from "../../../assets/images/dashboard/MATIC Icon.png"
import UserStore from "../../../store/UserStore";
import TilesFilter from "./TilesFilter";
import tilesLoader from "../../../assets/images/loading.gif"
import { Card } from "react-bootstrap";

function NonFilteredTile(props) {
    const Currency = useSelector((state) => state.curr);
    const { currentTheme } = useSelector((state) => state.Theme);
  
    const history = useHistory();
    const { t } = useTranslation();
    const curr = useSelector((state) => state.curr);
    let amount = props.amount;
    let accumulatedRewards = "Accumulated Rewards";
    // let totalRewards = "Total Rewards";
    let totalRewards = "Annual Rewards";
  
  
  
    const [dataAmount, setAmount] = useState(amount)
    const [iconShow, seticonShow] = useState(false)
  
    // const FixedAmount = (props.amount * curr.value).toFixed(curr.precision)
  
    const boxnameclass =
      props.boxname === 0
        ? style.lst_box1
        : props.boxname === 1
          ? style.lst_box2
          : props.boxname === 2
            ? style.lst_box3
            : props.boxname === 3
              ? style.lst_box1
              : props.boxname === 4
                ? style.lst_box2
                : style.lst_box4;
    const images =
      props.boxname === 0
        ? wallet
        : props.boxname === 1
          ? commission
          : props.boxname === 2
            ? payment
            : props.boxname === 3
              ? commission1
              : props.boxname === 4
                ? matic
                : pending;
  
    // const dashboardtileHandler = (e) => {
    //   e.preventDefault();
    //   if (props.text === "commision") {
    //     UserStore.checkUserEarnings = true;
    //   }
    //   history.push(props.to);
    // };
    const FiterData = [
      {
        code: "all",
        value: "",
      },
      {
        code: "thisYear",
        value: "",
      },
      {
        code: "thisMonth",
        value: "",
      },
      {
        code: "thisWeek",
        value: "",
      },
    ];
    const filterChange = (key) => {
      props.filterChange(props.text, key);
    };
  
    useEffect(() => {
      //setAmount(amount)
      // if(props.text=="cwrAnually"){
      //   const interval = setInterval(() => {
      //   setAmount(prevstate => (parseInt(prevstate+ 1)));
      //   }, 3000);
      //   return () => clearInterval(interval);
  
      // }
      // else{
      //   setAmount(amount)
      // }
    }, []);
  
    const Iconchage = () => {
      seticonShow(!iconShow)
    }
  return (
    <div>NonFilteredTile</div>
  )
}

export default NonFilteredTile