import React, { useEffect, useState } from "react";
import style from "./dashboard.module.scss";
import wallet from "../../../assets/images/dashboard/wallet-icon.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CurrencyDecimalFormat } from "../../helper";
import commission from "../../../assets/images/dashboard/commision-earned.png";
import commission1 from "../../../assets/images/dashboard/USDC Icon.png";
import payment from "../../../assets/images/dashboard/USDT Icon.png";
import pending from "../../../assets/images/dashboard/pending-5-861794.png";
import matic from "../../../assets/images/dashboard/MATIC Icon.png"
import UserStore from "../../../store/UserStore";
import TilesFilter from "./TilesFilter";
import tilesLoader from "../../../assets/images/loading.gif"
import { Card } from "react-bootstrap";
const TileItems = React.memo((props) => {
  console.log("tilesitemmssss", props)
  // console.log("🚀 item", props.accumulatedRewards)
  const Currency = useSelector((state) => state.curr);
  const { currentTheme } = useSelector((state) => state.Theme);

  const history = useHistory();
  const { t } = useTranslation();
  const curr = useSelector((state) => state.curr);
  let amount = props.amount;
  let accumulatedRewards = "Accumulated Rewards";
  // let totalRewards = "Total Rewards";
  let totalRewards = "Annual Rewards";



  const [dataAmount, setAmount] = useState(amount)
  const [iconShow, seticonShow] = useState(false)

  // const FixedAmount = (props.amount * curr.value).toFixed(curr.precision)

  const boxnameclass =
    props.boxname === 0
      ? style.lst_box1
      : props.boxname === 1
        ? style.lst_box2
        : props.boxname === 2
          ? style.lst_box3
          : props.boxname === 3
            ? style.lst_box1
            : props.boxname === 4
              ? style.lst_box2
              : style.lst_box4;
  const images =
    props.text === 'stakeEarnings'
      ? wallet
      : props.text === 'cwrAnually'
        ? commission
        : props.text === "usdt"
          ? payment
          : props.text === "ustc"
            ? commission1
            : props.text === "matic"
              ? matic
              : pending;

  // const dashboardtileHandler = (e) => {
  //   e.preventDefault();
  //   if (props.text === "commision") {
  //     UserStore.checkUserEarnings = true;
  //   }
  //   history.push(props.to);
  // };
  const FiterData = [
    {
      code: "all",
      value: "",
    },
    {
      code: "thisYear",
      value: "",
    },
    {
      code: "thisMonth",
      value: "",
    },
    {
      code: "thisWeek",
      value: "",
    },
  ];
  const filterChange = (key) => {
    props.filterChange(props.text, key);
  };

  useEffect(() => {
    //setAmount(amount)
    // if(props.text=="cwrAnually"){
    //   const interval = setInterval(() => {
    //   setAmount(prevstate => (parseInt(prevstate+ 1)));
    //   }, 3000);
    //   return () => clearInterval(interval);

    // }
    // else{
    //   setAmount(amount)
    // }
  }, []);

  const Iconchage = () => {
    seticonShow(!iconShow)
  }
  return (
    <>
      <div className={`${style.list_boxes_main} dashboard_tiles_Second_lst`}>
        <div className={`${currentTheme == "theme-dark"
          ? `${style.dark_mode_dashboard_pannel_box} ${boxnameclass}`
          : `${style.dashboard_pannel_box} ${boxnameclass}`
          }`}>
          {props.filter && (
            <TilesFilter items={FiterData}
              // showIcon={props.icon}
              showIcon={iconShow}
              // onChange={filterChange} 
              onChange={Iconchage}
            />
          )}
          <div className={style.listing_box_sec}
          // onClick={dashboardtileHandler}
          >
            <div className={style.listing_box_ico_rnd} style={{display:props.filter?'block':'none'}}>
              <img src={images} alt="" />
            </div>

            {/* <div className="sample-rows">

            </div> */}
            {/* <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /> */}
            <div style={{ width: "100%"}}>

              {
                props.lodervalue ? (
                  <div className={style.listing_box_cnout}>
                    <img src={tilesLoader} width="40px" />
                  </div>
                ) : (

                  <>
                    {
                      props.filter ?
                        <>
                          <div className={`${currentTheme == "theme-dark"
                            ? `${style.dark_mode_listing_box_head}`
                            : `${style.listing_box_head}`
                            }`}>
                            {t("Tails." + props.text)}
                            <div>
                            </div>
                          </div>

                          <div className={style.listing_box_cnout}>

                            <div style={{ display: "flex", justifyContent: "space-between", width:"100%" }}>
                              <div className="filteredTile">
                                <div className={style.tailHeading}>{t('Common.balance')}</div>
                                {


                                  iconShow ? amount.toLocaleString('en-US') : '****'


                                }

                              </div>

                            </div>

                          </div>
                        </>


                        : <div className={style.listing_box_cnout}>
                          <>
                          <div className={`${currentTheme == "theme-dark"
                            ? `${style.dark_mode_listing_box_head}`
                            : `${style.listing_box_head}`
                            }`}>
                            {t("Tails." + props.text)}
                            <div>
                            </div>
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-between", width:"100%"  }}>
                            <div >
                              <div className={style.tailHeading}>{accumulatedRewards}</div>
                              {

                                props.accumulatedRewards.toFixed(8).toLocaleString('en-US')
                              }



                            </div>
                            &nbsp; &nbsp;
                            <div>
                              <div className={style.tailHeading}>{totalRewards}</div>

                              {/* {props.filter ?'': props.totalRewards} */}
                              {props.AnnualRewards.toFixed(3).toLocaleString('en-US')}
                            </div>
                          </div>
                          </>

                          

                        </div>
                    }



                  </>




                )
              }

            </div>


          </div>

        </div>
      </div>


    </>
  );
});
export default TileItems;
