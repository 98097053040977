import React, { useState, useEffect } from "react";
import style from "./dashboard.module.scss";
import "./Earnandperform.css";
// import Tabs from 'react-responsive-tabs';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CurrencyDecimalFormat } from "../../helper";
import "react-responsive-tabs/styles.css";
import nodata from "../../../assets/images/nophoto/no-datas-found.png"

import { IMG_URL } from '../../../configuration/apiconfig';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const brdercontent = {
  marginBottom: "20px",
  paddingBottom: "30px",
  borderBottom: "1px #e5e5e5 solid",
};

function EarningsTab({ income, expenses, payoutstatus, lodervalue }) {
  //income,expenses,payoutstatus
  const { currentTheme } = useSelector((state) => state.Theme);
  let themevalue;
  if (currentTheme == "theme-dark") {
    themevalue = true;
  } else {
    themevalue = false;
  }
  const [toggleState, setToggleState] = useState(1);
  const [tabs, setTabs] = useState([]);
  const [content, setContent] = useState("earnings");
  const curr = useSelector((state) => state.curr);

  const { t } = useTranslation();

  const toggleTab = (index, content) => {
    setToggleState(index);
    setContent(content);
  };

  const [state, setState] = useState({
    earnings: [],
    expensess: [],
    payoutStatus: [],
  });
  const { earnings, expensess, payoutStatus } = state;

  function splitword(word) {
    var splitWord = t("Common." + word).split(" ");
    let result = "";
    splitWord.forEach((singleWord) => {
      result += singleWord[0];
    });
    return result;
  }

  const remove_duplicates = (array_) => {

    let newArray = array_.filter((c, index) => {
      return array_.indexOf(c) === index && c != undefined;
    });
    return newArray

   
  };

  useEffect(() => {
    if (income) {
      setState((prevstate) => ({
        ...prevstate,
        earnings: income,
      }));
      setTabs((prevstate) => [...prevstate, "earnings"]);
    }
    // if (expenses) {
    //   setState((prevstate) => ({
    //     ...prevstate,
    //     expensess: expenses,
    //   }));
    //   setTabs((prevstate) => [...prevstate, "expenses"]);
    // }

    // if (payoutstatus) {
    //   let payout = Object.keys(payoutstatus).map((key) => {
    //     return {
    //       title: key,
    //       value: payoutstatus[key],
    //     };
    //   });
    //   setState((prevstate) => ({
    //     ...prevstate,
    //     payoutStatus: payout,
    //   }));
    //   setTabs((prevstate) => [...prevstate, "payoutStatus"]);
    // }

    // managefunction()
  }, [income, expenses, payoutstatus]);

  return (
    <div className={style.dashbrd_earing_exp_sec}>
      <div
        className={`${currentTheme == "theme-dark"
            ? `${style.dark_mode_dashboard_pannel_box}`
            : `${style.dashboard_pannel_box}`
          }`}
      >
        {lodervalue ? (
          <>
            <div className={style.dashboard_new_member_head}>
              <Skeleton width={250} count={1} />
            </div>
            <div className="earn_n_team_content-tabs">
              <div className={style.earnings} style={brdercontent}></div>
              <Skeleton width={120} count={1} />
            </div>
          </>
        ) : (
          <>
            <div
              className={`${currentTheme == "theme-dark"
                  ? `${style.dark_mode_dashboard_new_member_head}`
                  : `${style.dashboard_new_member_head}`
                }`}
            >
              {t("Common.earningsExp")}
            </div>

            <div className={style.dashbord_earning_expence_cnt_sc}>
              {/* Title Start */}

              <div className="earn_n_team_bloc-tabs">
                {remove_duplicates(tabs).map((obj, index) => {
                  //   tabs.map((obj, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        toggleState === index + 1
                          ? "earn_n_team_tabs earn_n_team_active-tabs"
                          : "earn_n_team_tabs"
                      }
                      onClick={() => toggleTab(index + 1, obj)}
                    >
                      {t("Common." + obj)}
                    </div>
                  );
                })}
              </div>

              {/* Title End */}

              <div className="earn_n_team_content-tabs">
                <div
                  className={
                    content === "earnings"
                      ? "earn_n_team_content  earn_n_team_active-content"
                      : "earn_n_team_content"
                  }
                >
                  {earnings.length > 0 ? (
                    earnings.map((obj, index) => {
                      console.log("earningss", obj)
                      return (
                        <div key={index}>
                          <div className={style.earnings} style={brdercontent}>
                            <div className={style.earning_tabs_row}>
                              <div
                                className={`${currentTheme == "theme-dark"
                                    ? `${style.dark_mode_earning_tabs_name}`
                                    : `${style.earning_tabs_name}`
                                  }`}
                              >
                                {t("Common." + obj.amount_type)}


                                <span>
                                  {curr.currentCurr}{" "}
                                  {(obj.amount * curr.value).toFixed(
                                    curr.precision
                                  )}
                                </span>
                              </div>
                              <div className={style.earning_right_ico}>
                                <span>{splitword(obj.amount_type)}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={`${style.NodataImage}`}>
                      <center>
                        <img
                          src={nodata}
                          alt="nodata"
                          style={{ width: "50%" }}
                        />
                      </center>
                    </div>
                  )}
                </div>

                <div
                  className={
                    content === "expenses"
                      ? "earn_n_team_content  earn_n_team_active-content"
                      : "earn_n_team_content"
                  }
                >
                  {expensess.length > 0 ? (
                    expensess.map((obj, index) => {
                      return (
                        <div key={index}>
                          <div className={style.expence} style={brdercontent}>
                            <div className={style.earning_tabs_row}>
                              <div
                                className={`${currentTheme == "theme-dark"
                                    ? `${style.dark_mode_earning_tabs_name}`
                                    : `${style.earning_tabs_name}`
                                  }`}
                              >
                                <span>{t("Common." + obj.amount_type).toUpperCase()}</span>
                                <span>
                                  {curr.currentCurr}{" "}
                                  {(obj.amount * curr.value).toFixed(
                                    curr.precision
                                  )}
                                </span>
                              </div>
                              <div className={style.earning_right_ico}>
                                <span>{splitword(obj.amount_type)}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={`${style.NodataImage}`}>
                      <center>
                        <img
                          src={nodata}
                          alt="nodata"
                          style={{ width: "50%" }}
                        />
                      </center>
                    </div>
                  )}
                </div>

                <div
                  className={
                    content === "payoutStatus"
                      ? "earn_n_team_content  earn_n_team_active-content"
                      : "earn_n_team_content"
                  }
                >
                  {payoutStatus.length > 0 ? (
                    payoutStatus.map((obj, index) => {
                      return (
                        <div key={index}>
                          <div className={style.earnings} style={brdercontent}>
                            <div className={style.earning_tabs_row}>
                              <div
                                className={`${currentTheme == "theme-dark"
                                    ? `${style.dark_mode_earning_tabs_name}`
                                    : `${style.earning_tabs_name}`
                                  }`}
                              >
                                {t("Common." + obj.title).toUpperCase()}
                                <span
                                  className={style.pydata}
                                  style={{
                                    color:
                                      currentTheme === "theme-dark"
                                        ? "white"
                                        : obj.title === "approved"
                                          ? "blue"
                                          : obj.title === "rejected"
                                            ? "red"
                                            : obj.title === "paid"
                                              ? "green"
                                              : "black",
                                  }}
                                >
                                  <span
                                    className={style.pydata}
                                    style={
                                      obj.title === "approved"
                                        ? { color: "blue" }
                                        : obj.title === "rejected"
                                          ? { color: "red" }
                                          : obj.title === "paid"
                                            ? { color: "green" }
                                            : { color: "black" }
                                    }
                                  >
                                    {" "}
                                    {curr.currentCurr}{" "}
                                    {(obj.value * curr.value).toFixed(
                                      curr.precision
                                    )}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={`${style.NodataImage}`}>
                      <center>
                        <img
                          src={nodata}
                          alt="nodata"
                          style={{ width: "50%" }}
                        />
                      </center>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

    </div>
  );
}

export default EarningsTab;
