import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//service
import { CurrencyDecimalFormat } from "../../helper";
import style from "./nft.module.scss";
import moment from 'moment'
import Filter from "./Filter";

function Stake_report({ ContentData, paginationchange }) {
    const { t } = useTranslation();
    const Currency = useSelector((state) => state.curr);
    const [date, SetDate] = useState({
        start: moment().startOf("month").format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),

    });
    const [state, setState] = useState({ data: [] });
    const { data } = state;
    const columns = [
        {
            name: t("Common.nftname").toUpperCase(),
            selector: (row) => row.nft,
            width: "30%",
        },

        {
            name: t("Common.date").toUpperCase(),
            selector: (row) => row.date,
            cell: (row) => moment(row.date).format("D MMM YY h:mm:ss a"),
        },
        {
            name: t("Common.stakeperiod").toUpperCase(),
            selector: (row) => row.stakePeriod,
        },
        {
            name: t("Common.vestingDate").toUpperCase(),
            selector: (row) => row.vestingDate,
            // cell: (row) => moment(row.vestingDate).format("D MMM YY"),
        },
        {
            name: t("Common.countDown").toUpperCase(),
            selector: (row) => row.countDown,
        },
        {
            name: t("Common.annualResult").toUpperCase(),
            selector: (row) => row.annualResult,
        }

    ];

    useEffect(() => {
        let Data =
            ContentData.data &&
            ContentData.data.map((resData) => {
                console.log("resdataa",resData)
                return {
                    classnameamount: style.debit,
                    nft: resData.nft,
                    date: resData.date,
                    stakePeriod:resData.stakePeriod,
                    vestingDate:resData.maturity_date,
                    countDown:resData.countDown,
                    annualResult:resData.annualResult
                };

            });
        setState((prev) => ({
            ...prev,
            data: Data,
        }));
    }, [ContentData, setState]);


    const handlePageChange = (inPage) => {
        paginationchange(inPage, ContentData.perPage);
    };
    const handlePerRowsChange = (perPage, inPage) => {
        paginationchange(inPage, perPage);
    };


    const resetData = () => {
        SetDate((prev) => ({
            ...prev,
            start: moment().startOf("month").format("YYYY-MM-DD"),
            end: moment().format("YYYY-MM-DD"),
        }));



        paginationchange(
            ContentData.inPage,
            ContentData.perPage,
            moment().startOf("month").format("MMMM D, YYYY"),
            moment().format("MMMM D, YYYY"),
        );
    };

    const filterHandlet = (start, end) => {
        SetDate((prev) => ({
            ...prev,
            start: start,
            end: end,

        }));

        paginationchange(
            ContentData.inPage,
            ContentData.perPage,
            start,
            end,
        );
    };

    return (
        <div>

            <Filter
                filterHandler={filterHandlet}
                resetHandler={resetData}
                isSingle={false}
                // exportData={exportData}
                ContentData={ContentData}

            />

            <DataTable
                columns={columns}
                data={data}
                highlightOnHover
                progressPending={ContentData.pending}
                progressComponent={<div>Loading</div>}
                persistTableHead
                noHeader
                pagination
                paginationServer
                paginationTotalRows={ContentData.totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                noDataComponent={t("Common.noDataRecordsToDisplay")}
                responsive
            />
        </div>
    )
}

export default Stake_report