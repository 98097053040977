import React from "react";
import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
//components
import PageTitle from "../../../components/common/pageTitle";
// import MainContent from "../../components/dashboard/MainContent";
import UnapprovedDashboard from "../../../components/dashboard/unApproved/UnapprovedDashboard"
//service
import DashboardService from "../../../../service/dashboard/Dashboard";
import AppinfoService from "../../../../service/common/Appinfo";
import Spinner from "../../../shared/Spinner";
import { withRouter } from "react-router";
import companyName from "../../../../store/mobxStore/companyName";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';

import style from '../../../components/common/Common.module.scss'
import { Button, Form, Image, Alert } from "react-bootstrap";

import { logout } from '../../../../store/action';
import UserStore from '../../../../store/UserStore';

import Navbar from './navbar'
import zIndex from "@mui/material/styles/zIndex";


const Home = (props) => {
  console.log("sadasd", props)
  const cplan = useSelector((state) => state.ProjectConfig.mlmPlan);
  const curr = useSelector(state => state.curr);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    memberStatus: false,
    loading: true,
    alert: {
      show: false,
      varient: "",
      message: "",
    },
    modal: {
      title: "",
      show: false,
      data: {},
      id: "",
    },
    continue: false,
    visitorid: "",
  });

  const service = new DashboardService();
  const commonService = new AppinfoService();
  const { t } = props;

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  const logoutHandler = () => {

    commonService.logOut().then((res) => {
      if (res.status) {
        dispatch(logout());
        localStorage.removeItem('login');
        localStorage.removeItem('apiKey');
        localStorage.removeItem('currency');
        UserStore.key = ''
        props.history.push('/login');

      }
      else {
        dispatch(logout());
        localStorage.removeItem('login');
        localStorage.removeItem('apiKey');
        localStorage.removeItem('currency');
        UserStore.key = ''
        props.history.push('/login');

      }
    })

  }

  //upload image
  const uploadImgae = (file, username, type) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("username", username ?? "");
    formData.append("type", type ?? "subscription_renewal");

    service.upload_reciept(formData).then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          enableFinish: true,
          showAlert: true,
          alertType: "success",
          AlertMessage: t("Common.Pyment_recipt_upload_successfully"),
          imageUploaded: true,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          enableFinish: false,
          showAlert: true,
          alertType: "danger",
          AlertMessage: t("Common.error_file_upload"),
        }));
      }
    });

  }

  useEffect(() => {
    const service = new DashboardService();
    const { state } = props.location;

    // if (localStorage.getItem("visitorid")) {
    //   setState((prevState) => ({
    //     ...prevState,
    //     modal: {
    //       title: "Enter OTP sent to your email",
    //       show: true,
    //       data: {},
    //       id: "",
    //     },
    //     // visitorid : res.visitor_id,
    //     continue: true,
    //   }));
    // }

    // var userEmail = getCookie("visitor");
    // if (state) {
    //   if (state.error) {
    //     setState((prevstate) => ({
    //       ...prevstate,
    //       alert: {
    //         show: true,
    //         varient: "danger",
    //         message: "validation." + state.message,
    //       },
    //     }));
    //   }
    // }
    // commonService.checkDemoUsers().then((res) => {
    //   if (res.data.is_preset_demo === "yes" && userEmail == null) {
    //     setState((prevState) => ({
    //       ...prevState,
    //       modal: {
    //         title: "Please fill in your details to continue",
    //         show: true,
    //         data: {},
    //         id: "",
    //       },
    //     }));
    //   } else {
    //     // props.history.push('/dashboard');
    //   }
    // });
    service.UnapprovedDashboardLoad().then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          ...res.data,
        }));
      } else {
        props.history.push("/logout");
      }
    });
    // },[])
  }, [props.history, props.location, curr]);

  const FiterGraph = (range) => {
    service.JoiningGraphFilter(range).then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          joining_graph_data_new: res.data.joining_graph_data_new,
        }));
      } else {
        props.history.push("/logout");
      }
    });
  };

  const TilesFitler = (type, range) => {
    service.TileFilter(type, range).then((res) => {
      if (res.status) {
        let newData = state.tiles.map((object) => {
          if (object.text === type) {
            return res.data;
          } else {
            return object;
          }
        });
        setState((prevState) => ({
          ...prevState,
          tiles: newData,
        }));
      } else {
        props.history.push("/logout");
      }
    });
  };

  const closeAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        show: false,
        message: "",
        varient: "",
      },
    }));
  };

  return (
    <div>
      {/* {console.log('sadasda')} */}
      {/* {alert("sdadada")} */}
      {/* <div style={{backgroundColor : "black"}}>how r u</div> */}
      {state.loading ? (
        <Spinner />
      ) : (
        <React.Suspense fallback={<Spinner />}>
          <Navbar />
          <Helmet>
            <title>
              {companyName.companyName} | {t("Sidemenu.Dashboard")}
              {/* {l} */}
            </title>
          </Helmet>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <PageTitle title="Dashboard" buttonOn={false} />
            <button style={{ marginRight: "30px", borderRadius: "30px", fontSize: "20px", marginTop: "25px", width: "60px", zIndex: "999" }} className={`${style.logoutButton}`} onClick={logoutHandler}><i class="fa fa-sign-out" aria-hidden="true"></i></button>
          </div>
          {state.showAlert && <div style={{ marginTop: "10px" }}>
            <Alert show={state.showAlert} variant={state.alertType} onClose={() => setState((prevState) => ({
              ...prevState,
              showAlert: false
            }))} dismissible>
              {state.AlertMessage}
            </Alert>
          </div>
          }
  {/* lll */}
          <UnapprovedDashboard
            {...state}
            setstate={setState}
            filterChange={TilesFitler}
            chartfilterChange={FiterGraph}
            alertClose={closeAlert}
            style={{ marginTop: state.showAlert ? "-2%" : "20px" }}
            lodervalue={state.loading}
            uploadImgae={uploadImgae}
          />
        </React.Suspense>
      )}
    </div>
  );
};
export default withTranslation()(withRouter(Home));
