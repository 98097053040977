import React, { useEffect, useState } from "react";
import style from "./tree.module.scss";
import { TitleHeaders } from "./";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Table } from "react-bootstrap";
import DownLineService from "../../../service/tree/downLine";
import ReactPaginate from "react-paginate";
import Spinner from "../../shared/Spinner";
import { IMG_URL } from "../../../configuration/apiconfig";
import Image from "../../../assets/images/nophoto/no_photo.jpg";
import { useSelector } from "react-redux";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";
import proPic from "../../../assets/images/nophoto/no_photo.jpg";

import { MaterialReactTable } from "material-react-table";

import { Box, Button } from "@mui/material";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { ExportToCsv } from "export-to-csv"; //or use your library of choice here

//data
import downlineData from "./downlinedata.json";

const DownLineContent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentTheme } = useSelector((state) => state.Theme);
  const service = new DownLineService();
  const [state, setState] = useState({
    level: "all",
    offset: 0,
    data: [],
    count: 0,
    totalLevels: [],
    showNext: true,
    loading: true,
    searchloader: false,
    page_count: 0,
    limit: 10,
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(100);
  useEffect(() => {
    service.getDownlineCount(state.level).then((res) => {
      console.log("count res === ", res);
      if (res.status) {
        setTotalCount(res.data);
      }
    });
  }, [state.level]);

  console.log(`pagination ==== `, pagination);
  const [loader, setloader] = useState(false);
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      data: downlineData.tableData,
      totalLevels: new Array(downlineData.total_levels)
        .fill()
        .map((_, idx) => idx + 1),
      count: downlineData.total_downline_count,
      loading: false,
      // level : res.data.total_levels
    }));
    setloader(true);
    service
      .getDownline(state.level, pagination.pageIndex, pagination.pageSize)
      .then((res) => {
        if (res.status) {
          setloader(false);
          setState((prev) => ({
            ...prev,
            data: res.data.tableData,
            totalLevels: new Array(res.data.total_levels)
              .fill()
              .map((_, idx) => idx + 1),
            count: res.data.total_downline_count,
            loading: false,
            offset: pagination.pageIndex,
            limit: pagination.pageSize,
            // level : res.data.total_levels
          }));
        } else {
          history.push("/login");
        }
      });
  }, [state.level, history, pagination]);

  const goToPreviousPath = () => {
    history.goBack();
  };

  //onreset button click
  const reset = () => {
    setState((prev) => ({
      ...prev,
      level: "all",
      offset: 0,
    }));
  };
  //serch the level wise list
  const searchLevel = (level) => {
    setState((prev) => ({
      ...prev,
      searchloader: true,
    }));

    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        level: level,
        searchloader: false,
      }));
    }, 1000);
  };
  const handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * 20);
    let showNext = true;
    if (offset > 0) {
      showNext = Math.floor(state.count / offset) === 1 ? false : true;
    }
    setState((prev) => ({
      ...prev,
      offset: offset,
      showNext: showNext,
    }));
  };

  const data = [
    {
      sponsor: "sponsor",
      profile_image:
        "https://ih1.redbubble.net/image.2387150415.7572/st,small,507x507-pad,600x600,f8f8f8.jpg",
      first_name: "firstanme",
      last_name: "last name",
      username: "username",
      affiliate: "affiliate",
      nfts: "nft",
      staked: "staked",
      level: "level",
      action: "bubinatiza",
    },
    {
      sponsor: "dfd",
      profile_image:
        "https://ih1.redbubble.net/image.2387150415.7572/st,small,507x507-pad,600x600,f8f8f8.jpg",
      first_name: "df",
      last_name: "last dfdgname",
      username: "fdgf",
      affiliate: "fgdfg",
      nfts: "5",
      staked: "fg",
      level: "5",
      action: "fggfg",
    },
  ];

  const columns = [
    {
      accessorKey: "sponsor",

      header: "Sponsor",
      size: 120,
    },

    {
      accessorKey: "profile_image",
      size: 50,
      header: "Profile Image",
      Cell: ({ renderedCellValue, row }) => (
        <Box
          sx={{
            display: "flex",

            alignItems: "center",

            gap: "1rem",
          }}
        >
          <img
            alt="avatar"
            height={30}
            src={
              row.original.profile_image ? row.original.profile_image : proPic
            }
            loading="lazy"
            style={{ borderRadius: "50%" }}
          />

          {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
        </Box>
      ),
    },

    {
      accessorKey: "first_name",

      header: "First Name",
      size: 100,
    },
    {
      accessorKey: "last_name",

      header: "Last Name",
      size: 100,
    },

    {
      accessorKey: "username",

      header: "Username",
      size: 100,
    },

    {
      accessorKey: "affiliate",

      header: "Affiliate/Customer",
      size: 100,
    },

    {
      accessorKey: "nfts",

      header: "NFTs",
      size: 50,
    },
    {
      accessorKey: "staked",

      header: "Staked",
      size: 50,
    },
    {
      accessorKey: "level",
      header: "Level",
      enableSorting: false,
      enableColumnFilter: false,
      size: 50,
    },
    {
      accessorKey: "action",

      header: "Action",
      size: 50,

      Cell: ({ renderedCellValue, row }) => (
        <Box
          sx={{
            display: "flex",

            alignItems: "center",

            gap: "1rem",
          }}
        >
          <Link
            to={`/genealogyTree/${row.original.action}`}
            className={`${
              currentTheme == "theme-dark"
                ? `${style.themedarkaction}`
                : `${style.themelightaction}`
            }`}
          >
            <div className={style.tooltip}>
              <i className="fa fa-sitemap"></i>
            </div>
          </Link>

          {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
        </Box>
      ),
    },
  ];

  return (
    <div className={style.DownlineMembers}>
      {state.loading ? (
        <Spinner />
      ) : (
        <>
          {history.location.state &&
          history.location.state.prevPath == "/genealogyTree" ? (
            <button className={style.back_btn} onClick={goToPreviousPath}>
              Back
              <i className=" btn_icon fa fa-backward"></i>
            </button>
          ) : (
            <></>
          )}

          <TitleHeaders
            tilesvalue="downlineMembers"
            count={state.count}
            level={state.totalLevels}
            searchLevel={searchLevel}
            reset={reset}
            searchloader={state.searchloader}
          />
          <div>
            <MaterialReactTable
              // enablePagination={false}
              onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
              state={{ pagination }}
              columns={columns}
              data={state.data}
              rowCount={totalCount}
              manualPagination={true}
              // state={{ isLoading: loader }}
              // enableRowSelection
              enableFilters={false}
              enableSorting={false}
              positionToolbarAlertBanner="bottom"
              enableFullScreenToggle={false}
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    p: "0.5rem",
                    flexWrap: "wrap",
                  }}
                ></Box>
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DownLineContent;
