import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import style from './registration.module.scss';
import api from '../../../api/api';
function Buttons(props) {
  console.log("🚀 button", props.loader)
  const [isApiCallComplete, setIsApiCallComplete] = useState(false);
  const handleFinishClick = async (payload) => {
    setIsApiCallComplete(false);
    try {
      // Perform the API call here
      const result = await api.registerSubmit(payload);
      console.log("result", result);
    } catch (error) {

      console.log(error);
    } finally {
      setIsApiCallComplete(true);
    }
  };
  const { t } = useTranslation();
  return (
    <div className={style.Buttons}>
      {
        props.prev &&
        <Button color="primary" onClick={props.prevButtonClick} className={`btn-pill ${style.PrevButton}`} >
          <i className="fa fa-chevron-left" />
          {t('profile.prev')} &nbsp;
        </Button>
      }
      {props.loader ? (
        <Button className={`btn-pill ${style.NextButton}`} disabled>
          <Spinner animation="border" size="sm" />
        </Button>
      ) : (
        <Button
          className={`btn-pill ${style.NextButton}`}
          type="submit"
          onClick={handleFinishClick}
          // style={{ opacity: isApiCallComplete ? 0.5 : 1 }}
        >

          {/* ll */}
          {!props.next ? t('profile.next') : t('Button.finish')} &nbsp;
          <i className="fa fa-chevron-right" />
        </Button>
      )}
    </div>
  )
}

export default Buttons
