import React, { useState } from "react";
import { Form, Tooltip, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { usePopperTooltip } from "react-popper-tooltip";
import { animated, useTransition } from "react-spring";
import DatePickers from "../common/Datepickers";
import "react-popper-tooltip/dist/styles.css";
import style from "./registration.module.scss";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { OverlayTrigger } from "react-bootstrap";
import { red } from "@mui/material/colors";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import { useEffect, useRef } from "react";

function FormsControl({ policy, validation, ...props }) {
  console.log("formcontrolss", props);
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const itiRef = useRef(null);

  const [countryCode, setcountryCode] = useState("");
  const [controlledVisible, setControlledVisible] = useState(false);
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
    usePopperTooltip({
      visible: controlledVisible,
      onVisibleChange: setControlledVisible,
      trigger: "focus",
    });
  const transitions = useTransition(controlledVisible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  const [flagTYpe, setflagTYpe] = useState("");
  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      // intlTelInput(input, {
      //   initialCountry: 'us',
      //   // preferredCountries: ["us", "gb"], // Set your preferred countries here
      //   separateDialCode: true,
      // });
      const iti = intlTelInput(input, {
        // initialCountry: 'us',
        initialCountry: props?.data?.flag ? props.data.flag : "us",
        //   // preferredCountries: ["us", "gb"], // Set your preferred countries here
        // separateDialCode: true,
      });
      itiRef.current = iti;

      const selectedCountryCode = iti.getSelectedCountryData();
      console.log("selectedCountryCode", selectedCountryCode);
      setcountryCode(selectedCountryCode.dialCode);
      setflagTYpe(selectedCountryCode.iso2);

      itiRef.current = iti;
      input.addEventListener("countrychange", handleCountryChange);
    }
  }, [inputRef.current]);
  const [showPswd, setshowpswd] = useState(false);

  const handleCountryChange = () => {
    const iti = itiRef.current;
    if (iti) {
      const phoneNumber = iti.getSelectedCountryData();
      setcountryCode(phoneNumber.dialCode);
      setflagTYpe(phoneNumber.iso2);
    }
  };

  console.log("flagetypeee", flagTYpe);

  const Components = (type) => {
    console.log("componentss", props.data.code);
    if (type === "number" && props.data.code == "mobile") {
      return (
        <Form.Group
          className={`${style.formGroup}`}
          style={{ marginBottom: "2px" }}
        >
          <div
          // className={style.mobile_num_fld_view}
          >
            <div
            // className={style.mobile_num_fld}
            >
              {/* <InputGroup className="mb-2">
                <InputGroup.Prepend>
                  <InputGroup.Text>@</InputGroup.Text>
                </InputGroup.Prepend>
                {/* <FormControl id="inlineFormInputGroup" placeholder="Username" /> */}
              {/* </InputGroup> */}

              <input
                ref={inputRef}
                type="tel"
                name={props.data.code}
                value={props.data.value ?? ""}
                disabled={!props.data.isEditable}
                className={`${
                  currentTheme === "theme-dark"
                    ? `${style.dark_mode_inputField} ${
                        props.data.error
                          ? `${style.formsHasError} form-control is-invalid`
                          : ""
                      }`
                    : `${style.inputField} ${
                        props.data.error
                          ? `${style.formsHasError} form-control is-invalid`
                          : ""
                      }`
                }`}
                // onChange={(e) => props.change(e, props.data.required)}
                onChange={(e) =>
                  props.PhoneCodeChangeHandler(e, countryCode, flagTYpe)
                }
                onBlur={() => props.PhonecodeBlurhandler(countryCode, flagTYpe)}
                // onBlur={() => {
                //   if (
                //     props.data.code === "sponsorUserName" ||
                //     props.data.code === "password" ||
                //     props.data.code === "userName"
                //   ) {
                //     props.blurhandler(props.data.code);
                //   }
                // }}
              />
            </div>
          </div>
        </Form.Group>
      );
    }
    if (
      type === "text" ||
      type === "number" ||
      (type === "password" && props.data.code === "confirmPassword") ||
      type === "email" ||
      type === "textarea"
    ) {
      return (
        <Form.Control
          ref={
            props.data.code === "password" &&
            props.data.validation.disableHelper
              ? setTriggerRef
              : null
          }
          placeholder={props?.data?.placeHolder ?? ""}
          id={props.data.code}
          type={type}
          name={props.data.code}
          value={props.data.value ?? ""}
          disabled={!props.data.isEditable}
          className={`${
            currentTheme == "theme-dark"
              ? `
              ${style.dark_mode_inputField}
              ${
                props.data.error
                  ? `${style.formsHasError}form-control is-invalid`
                  : ""
              }
              `
              : `
              ${style.inputField}
              ${
                props.data.error
                  ? `${style.formsHasError}form-control is-invalid`
                  : ""
              }
              `
          }`}
          onChange={(e) => props.change(e, props.data.required)}
          onBlur={() => {
            if (
              props.data.code === "sponsorUserName" ||
              props.data.code === "password" ||
              props.data.code === "userName"
            ) {
              props.blurhandler(props.data.code);
            }
          }}
        />
      );
    } else if (type === "password" && props.data.code === "password") {
      return (
        <div class="pwd">
          <Form.Control
            ref={
              props.data.code === "password" &&
              props.data.validation.disableHelper
                ? setTriggerRef
                : null
            }
            id={props.data.code}
            type={showPswd ? "text" : type}
            name={props.data.code}
            value={props.data.value ?? ""}
            disabled={!props.data.isEditable}
            className={`${
              currentTheme == "theme-dark"
                ? `
          ${style.dark_mode_inputField}
          ${
            props.data.error
              ? `${style.formsHasError}form-control is-invalid`
              : ""
          }
          `
                : `
          ${style.inputField}
          ${
            props.data.error
              ? `${style.formsHasError}form-control is-invalid`
              : ""
          }
          `
            }`}
            onChange={(e) => props.change(e, props.data.required)}
            onBlur={() => {
              if (
                props.data.code === "sponsorUserName" ||
                props.data.code === "password" ||
                props.data.code === "userName"
              ) {
                props.blurhandler(props.data.code);
              }
            }}
          />

          <span class="p-viewer" onClick={() => setshowpswd(!showPswd)}>
            {showPswd ? <VisibilityOffIcon /> : <VisibilityIcon />}
            {/* <i class="fa fa-eye" aria-hidden="true"></i> */}
          </span>
        </div>
      );
    } else if (type === "tel") {
      return (
        <Form.Control
          ref={
            props.data.code === "password" &&
            props.data.validation.disableHelper === 0
              ? setTriggerRef
              : null
          }
          id={props.data.code}
          type={type}
          pattern={"[0-9]{2,3}[0-9]{4,6}[0-9]{3}[0-9]{3}"}
          // placeholder={`Eg: 00918912345677`}
          placeholder={props?.data?.placeHolder ?? ""}
          name={props.data.code}
          value={props.data.value ?? ""}
          disabled={!props.data.isEditable}
          className={`${
            currentTheme == "theme-dark"
              ? `
              ${style.dark_mode_inputField}
              ${
                props.data.error
                  ? `${style.formsHasError}form-control is-invalid`
                  : ""
              }
              `
              : `
              ${style.inputField}
              ${
                props.data.error
                  ? `${style.formsHasError}form-control is-invalid`
                  : ""
              }
              `
          }`}
          onChange={(e) => props.change(e, props.data.required)}
          onBlur={() => {
            if (
              props.data.code === "sponsorUserName" ||
              props.data.code === "password" ||
              props.data.code === "userName"
            ) {
              props.blurhandler(props.data.code);
            }
          }}
        />
      );
    } else if (type === "select") {
      return (
        <Form.Control
          as="select"
          disabled={props.status || props.data.disabled}
          className={`${style.inputField}`}
          name={props.data.code}
          onChange={(e) => props.change(e, props.data.required)}
          // onChange={ (e)=>{props.dropdownChange(e.target.value,props.data)}}
          value={props.data.value ?? ""}
        >
          <option value="">
            {t("registration.selectOption", { field: props.data.code })}
          </option>
          {props.data.options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {t(option.code)}
              </option>
            );
          })}
        </Form.Control>
      );
    }
    if (props.data.code === "agree_terms" && type === "checkbox") {
      return (
        <div className={style.checkbox}>
          <input
            type="checkbox"
            name={props.data.code}
            className={style.checkboxInput}
            onChange={(e) => props.change(e, props.data.required)}
            value={props.data.value}
            defaultChecked={props.data.value}
            // ref={el => (this.selector = el)}
            id="terms"
            // {...inputProps}
          />
          <label
            className={`${style.checkboxLabel} ${
              props.data.required && style.required
            }  ${props.data.error && style.isInvalid}`}
            onClick={() => props.openModal(props.data.content)}
          >
            {t("Common.acceptTermsAndCondietion")}
          </label>
        </div>
      );
    }
    if (props.data.code === "become_affiliate" && type === "checkbox") {
      console.log("data", props.data);
      return (
        <div className={style.checkbox}>
          {props.userType === "affiliate" && (
            <>
              <input
                type="checkbox"
                name={props.data.code}
                className={style.checkboxInput}
                onChange={(e) => props.change(e, props.data.required)}
                value={props.data.value}
                defaultChecked={props.data.value}
                // ref={el => (this.selector = el)}
                id="terms"
                // {...inputProps}
              />
              <label
                className={`${style.checkboxLabel} ${
                  props.data.required && style.required
                }  ${props.data.error && style.isInvalid}`}
                onClick={() => props.openModal(props.data.content)}
              >
                {t("I ACCEPT TO BECOME AN AFFILIATE")}
              </label>
            </>
          )}
        </div>
      );
    } else if (type === "checkbox" && props.data.code === "phone_validation") {
      return (
        <div
          className={style.checkbox}
          style={{
            float: "left",

            width: "100%",
          }}
        >
          <label
            className={`${style.checkboxLabel} ${
              props.data.required && style.required
            }  ${props.data.error && style.isInvalid}`}
            style={{
              margin: "0",
              paddingLeft: "0",
            }}
          >
            {t("Common.verifyme")}
          </label>
          <input
            type="checkbox"
            name={props.data.code}
            className={style.checkboxInput}
            onChange={(e) => props.otpModel(e, props.data.value)}
            value={props.data.value}
            defaultChecked={props.data.value}
            checked={props.data.value}
            // onClick={() => props.otpModel(props.data.value)}
            // ref={el => (this.selector = el)}
            id="terms"
            // {...inputProps}
          />
        </div>
      );
    } else if (type === "checkbox" && props.data.code === "mail_validation") {
      return (
        <div
          className={style.checkbox}
          style={{
            float: "left",
            marginTop: "-20px",
            width: "100%",
          }}
        >
          <label
            className={`${style.checkboxLabel} ${
              props.data.required && style.required
            }  ${props.data.error && style.isInvalid}`}
            style={{
              margin: "0",
              paddingLeft: "0",
            }}
          >
            {t("Common.verifyme")}
          </label>
          <input
            type="checkbox"
            name={props.data.code}
            className={style.checkboxInput}
            onChange={(e) => props.MailOtpModel(e, props.data.value)}
            value={props.data.value}
            defaultChecked={props.data.value}
            checked={props.data.value}
            // onClick={() => props.otpModel(props.data.value)}
            // ref={el => (this.selector = el)}
            id="terms"
            // {...inputProps}
          />
        </div>
      );
    } else if (type === "date") {
      return (
        <DatePickers
          disabled={false}
          start={props.data.value}
          datechange={props.selectDate}
          id={props.data.code}
        />
      );
    }
  };
  return (
    <Form.Group>
      {props.data.code !== "agree_terms" &&
        props.data.code !== "become_affiliate" &&
        props.data.code !== "phone_validation" &&
        props.data.code !== "mobile" &&
        props.data.code !== "mail_validation" && (
          <Form.Label
            htmlFor={props.data.code}
            className={`${props.data.required && style.required} ${
              props.data.error && style.isInvalid
            }`}
          >
            {props.data.custom
              ? props.data.code
              : t("profile." + props.data.code)}
          </Form.Label>
        )}
      {props.data.code === "mobile" && (
        <div style={{ display: "inline-flex" }}>
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip>
                <ul>
                  <li>Numbers only</li>
                </ul>
              </Tooltip>
            }
          >
            <InfoOutlinedIcon sx={{ color: red[500] }} fontSize="small" />
          </OverlayTrigger>

          <Form.Label
            htmlFor={props.data.code}
            className={`${props.data.required && style.required} ${
              props.data.error && style.isInvalid
            }`}
            style={{ marginTop: "5px", marginLeft: "2px" }}
          >
            {props.data.custom
              ? props.data.code
              : t("profile." + props.data.code)}
          </Form.Label>
          {props.data.code === "mobile" && (
            <strong>
              <span>&nbsp;&nbsp;{`( +${countryCode} )`}</span>
            </strong>
          )}
        </div>
      )}
      {Components(props.data.type)}
      {props.data.code === "country" && (
        <div className={style.countryInfo}>
          <span>
            Platform not open to FATF listed countries or to Citizens or
            Residents of the United States
          </span>
        </div>
      )}
      {props.data.code === "password" &&
        props.data.validation.disableHelper &&
        transitions.map(
          ({ item, key, props }) =>
            item && (
              <animated.div
                key={key}
                ref={setTooltipRef}
                {...getTooltipProps({
                  className: "tooltip-container",
                  style: props,
                })}
              >
                <div>
                  {Object.keys(validation).map(function (key, index) {
                    if (key !== "disableHelper") {
                      return (
                        <p
                          key={index}
                          style={
                            currentTheme === "theme-dark"
                              ? { color: "white" }
                              : { color: "black" }
                          }
                        >
                          {policy[key] ? (
                            <i
                              className="fa fa-check-circle"
                              style={{ color: "green" }}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-times-circle"
                              style={{ color: "#f05050" }}
                            ></i>
                          )}
                          {t("validation.atleast_" + key, {
                            count: validation[key],
                          })}
                        </p>
                      );
                    }
                    return false;
                  })}
                </div>
                <div {...getArrowProps({ className: "tooltip-arrow" })} />
              </animated.div>
            )
        )}

      {/* error message */}
      <Form.Text
        className={`
                ${props.data.error ? style.isInvalid : "d-none"}
                `}
      >
        {" "}
        {props.data.custom
          ? "Please enter " + props.data.code
          : t(props.data.error, props.data.errorField)}
      </Form.Text>
    </Form.Group>
  );
}

export default FormsControl;
