import React, { useCallback, useEffect, useState } from "react";
import style from "../crm/crm.module.scss";
import Form from 'react-bootstrap/Form';
import {
  DatePickers,
  FormInputControl,
  DropDownBs,
  CustomButton,
  AlertBs,
} from "../common";
//import moment from 'moment';
//service
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CrmService from "../../../service/crm/Crm";
import AppinfoService from "../../../service/common/Appinfo";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import NFTService from "../../../service/NFT/nft";
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { Web3Button } from '@web3modal/react'
import { useAccount } from "wagmi";
import { useWeb3Modal } from '@web3modal/react'
import { disconnect } from '@wagmi/core'
import { arbitrum, mainnet, polygon, goerli, polygonMumbai } from 'wagmi/chains'




const chains = [polygonMumbai]
const projectId = '7ba2770b043ebf407c1e423b8272e4e9'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)


function AddForm({ modalclose, getErrormsg }) {
  const { open, close } = useWeb3Modal()
  const { currentTheme } = useSelector((state) => state.Theme);
  const [success, setSuccess] = useState({
    alert: {
      show: false,
      type: "",
      message: ""
    }
  });

  const {status}=useSelector((state)=>state.time)
  const [errors, setErrors] = useState();
  const [loader, setloader] = useState(false)
  const [username, setusername] = useState("")
  const service = new NFTService();
  const { t } = useTranslation();
  const history = useHistory();
  // const { currentstatus } = useSelector((state) => state.currentstatus);

  const [modal, setmodal] = useState({
    show: false
  })


  const [state, setstate] = useState({
    propsData: {
      label: '',
      icon: "",
      subtittle: "",
      buttonSubmit: "",
      fadeClass: true
    }
  })
  const [UpdateStatus, setUpdateStatus] = useState(false)

  const { address, isConnected } = useAccount()

  console.log("addresssss", address, isConnected)
  const UpdateWallet = () => {
    setloader(true)


    if (address && isConnected) {
      let data = {
        wallet_address: address
      }
      service.nftWalletUpdate(data).then((res) => {
        if (res.status) {
          setloader(false)
          setUpdateStatus(true)
          setSuccess((prev) => ({
            ...prev,
            alert: {
              ...prev,
              show: true,
              message: t('Common.addressUpdated'),
              type: 'success'
            }
          }))
          setTimeout(() => { modalclose(); }, 1000)
          getErrormsg("success", "Wallet Address Updated")
        }
        else {
          setUpdateStatus(false)
          if (res.error.code === 1087) {
            getErrormsg("warning", res?.error?.description)
            setloader(false)
            disconnect?.()
            setErrors(res?.error?.description)
          } else {
            setloader(false)
            setErrors(res?.error?.description)
            getErrormsg("warning", res?.error?.description)
          }

        }
      })
    }
  }

  useEffect(() => {
    if (!isConnected) {
      setUpdateStatus(false)
    }
    console.log("addresss and connection status", isConnected, address)
  }, [isConnected])

  // let result




  const handleAlertclose = () => {
    setSuccess((prev) => ({
      ...prev,
      alert: {
        ...prev.alert,
        show: false,
        message: "",
        type: ""
      }
    }))
  }
  // console.log("logoutstatusstatus",status)
  useEffect(()=>{
    const username=localStorage.getItem("username")
    console.log("usernameee",username)
   
  },[])
  return (
    <div
   
    >
      <div>
        
        <div>
          <div className={style.wallectConnect}>
          
            <WagmiConfig config={wagmiConfig}>
              <Web3Button />
          
            </WagmiConfig>
            <Web3Modal projectId={projectId} ethereumClient={ethereumClient}
              explorerExcludedWalletIds={'ALL'}
              explorerRecommendedWalletIds={[
                'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
                '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
                'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
                '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927'
              ]}
           
            />
            {
              (isConnected) && (
                <div className={style.walletconnected_bx}>


                  <h4 className={style.Walletheading}>Wallet Details</h4>


                  <>
                    <Form>
                     
                      <div className={style.AddressField}>
                        <span style={{ fontWeight: 'bolder' }}>{address}</span>
                      </div>

                      <Form.Label>
                        <small style={{ fontSize: '12px' }}>Your account
                          <span style={{ color: '#2b9797' }}>(connected)</span></small>
                      </Form.Label><br />

                      {
                        UpdateStatus && (
                          <AlertBs
                            varient={success.alert.type}
                            show={success.alert.show}
                            message={success.alert.message}
                            close={handleAlertclose}
                          />
                        )
                      }

                      {
                        UpdateStatus === false &&
                        <Button variant="primary" onClick={UpdateWallet} className={style.updateBTn} disabled={loader}>
                          {
                            loader && (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px", backgroundColor: "transparent" }}
                              />
                            )
                          }

                          Update Your Address



                        </Button>
                      }




                    </Form>
                    
                  </>
                 
                </div>

              )
            }


          </div>        

        </div>
       
      </div>
    </div>
  );
}
export default AddForm;
