import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./dashboard.module.scss";
import { Link } from "react-router-dom";
import Links from "./Links";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import proPic from "../../../assets/images/nophoto/no_photo.jpg";
import tilesLoader from "../../../assets/images/loading.gif";
import AddForm from "./AddForm";
const Profiles = React.memo((props) => {
  console.log("111111", props.profile.affiliate);
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  return (
    <div
      className={`${
        currentTheme == "theme-dark"
          ? style.dark_mode_dashboard_pannel_box
          : style.dashboard_pannel_box
      }`}
    >
      {props.profile && (
        <Row>
          <Col xl={4} lg={4} md={12}>
            <div className={style.profile_main_sec}>
              <div className={style.profile_main_img_bx}>
                <img
                  src={
                    props.profile.user_photo ? props.profile.user_photo : proPic
                  }
                  alt="No Image"
                />
              </div>
              <div
                className={`${
                  currentTheme == "theme-dark"
                    ? style.dark_mode_profile_main_txt_sc
                    : style.profile_main_txt_sc
                }`}
              >
                {props.lodervalue ? (
                  <div>
                    <img src={tilesLoader} width="40px" />
                  </div>
                ) : (
                  <div>
                    <strong>{props.profile.full_name} </strong>
                    <span>{props.profile.user_name}</span>
                  </div>
                )}

                <Link
                  to="/profile"
                  className={`${
                    currentTheme == "theme-dark"
                      ? style.dark_mode_view_prfl_btn
                      : style.view_prfl_btn
                  }`}
                >
                  {t("Dashboard.viewProfile")}
                </Link>
                <br />
              </div>
            </div>
          </Col>

          <Col xl={4} lg={4} md={12}>
            {props?.affiliate_status ? (
              <div className={style.profile_soc_share_sec}>
                <div className={style.profilesoc_share_bx}>
                  <div
                    className={`${
                      currentTheme == "theme-dark"
                        ? style.dark_mode_profilesoc_share_bx_hd
                        : style.profilesoc_share_bx_hd
                    }`}
                  >
                    {t("Dashboard.replicaLinks")}
                  </div>
                  <div
                    className={`${
                      currentTheme == "theme-dark"
                        ? style.dark_mode_profilesoc_share_bx_row
                        : style.profilesoc_share_bx_row
                    }`}
                  >
                    {props.lodervalue ? (
                      <div>
                        <img src={tilesLoader} width="40px" />
                      </div>
                    ) : (
                      <>
                        {props.profile.replica.map((link, index) => {
                          return (
                            <div
                              className={`${
                                currentTheme == "theme-dark" &&
                                `${style.dark_mode_profilesoc_share_bx_ico}`
                              }`}
                            >
                              <Links
                                iscopy={
                                  // link.icon === "fa fa-files-o" ? true : false
                                  link?.isCopy ?? false
                                }
                                icon={link.icon}
                                key={index}
                                link={link.link}
                                code={link?.code ?? ""}
                              />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                  {props.profile.lead_capture && (
                  <div className={style.profilesoc_share_bx}>
                    {/* <div
                      className={`${
                        currentTheme == "theme-dark"
                          ? style.dark_mode_profilesoc_share_bx_hd
                          : style.profilesoc_share_bx_hd
                      }`}
                    >
                      {t("Dashboard.leadCapture")}
                    </div> */}
                    <div
                      className={`${
                        currentTheme == "theme-dark"
                          ? style.dark_mode_profilesoc_share_bx_row
                          : style.profilesoc_share_bx_row
                      }`}
                    >
                      {props.profile.lead_capture.map((link, index) => {
                        return (
                          <div
                            className={`${
                              currentTheme == "theme-dark" &&
                              `${style.dark_mode_profilesoc_share_bx_ico}`
                            }`}
                          >
                            <Links
                              iscopy={
                                // link.icon === "fa fa-files-o" ? true : false
                                link?.isCopy ?? false
                              }
                              icon={link.icon}
                              key={index}
                              link={link.link}
                              code={link?.code ?? ""}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                  
                </div>

             
              </div>
            ) : (
              <div
                className={`${
                  currentTheme == "theme-dark"
                    ? style.dark_mode_profile_other_box
                    : style.profile_other_box
                }`}
              >
                <div className={style.profile_other_box_cnt}>
                  <span>
                    {t("Common.placement")}:
                    <br />
                    {props.lodervalue ? (
                      <div>
                        <img src={tilesLoader} width="40px" />
                      </div>
                    ) : (
                      <strong>{props.profile.placement}</strong>
                    )}
                  </span>
                </div>
              </div>
            )}
          </Col>

          {(props.profile.membership_package || props.profile.rank) && (
            <Col xl={2} lg={2} md={6}>
              <div
                className={`${
                  currentTheme == "theme-dark"
                    ? style.dark_mode_profile_other_box
                    : style.profile_other_box
                }`}
              >
                {/* {props.profile.membership_package && (
                  <div
                    className={`${
                      currentTheme == "theme-dark"
                        ? style.dark_mode_profilesoc_share_bx_hd
                        : style.profilesoc_share_bx_hd
                    }`}
                  >
                    <span>
                      {t("Dashboard.membershipPackage")}
                      <br />
                      <strong>
                        {props.profile.membership_package.name ?? "NA"}{" "}
                      </strong>
                    </span>
                  </div>
                )} */}
                {props.affiliate_status && (
                  <div className={style.profile_other_box_cnt}>
                    <span>
                      {t("Common.rank").toUpperCase()}
                      <br />
                      {props.lodervalue ? (
                        <div>
                          <img src={tilesLoader} width="40px" />
                        </div>
                      ) : (
                        <>
                          {props.profile.rank && (
                            <strong>{props.profile.rank.curent_rank}</strong>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                )}

                <div className={style.profile_other_box_cnt}>
                  <span>
                    <br />
                    {props.lodervalue ? (
                      <div>
                        <img src={tilesLoader} width="40px" />
                      </div>
                    ) : (
                      <>
                        {props.profile.affiliate === false ? (
                          <Link to="/termsndcondition">
                            <span style={{ color: "black" }}>
                              {t("Member Type").toUpperCase()}
                            </span>
                            <Button
                              style={{
                                height: "50px",
                                width: "105px",
                                fontSize: "12px",
                                // fontWeight: "bold",
                                borderRadius: "15px",
                              }}
                            >
                              Become Affiliate
                            </Button>
                          </Link>
                        ) : null}
                      </>
                    )}
                  </span>
                </div>
              </div>
            </Col>
          )}

          {props.affiliate_status && (
            <Col xl={2} lg={2} md={6}>
              <div
                className={`${
                  currentTheme == "theme-dark"
                    ? style.dark_mode_profile_other_box
                    : style.profile_other_box
                }`}
              >
                <div className={style.profile_other_box_cnt}>
                  <span>
                    {t("Common.placement")}:
                    <br />
                    {props.lodervalue ? (
                      <div>
                        <img src={tilesLoader} width="40px" />
                      </div>
                    ) : (
                      <strong>{props.profile.placement}</strong>
                    )}
                  </span>
                </div>
              </div>
            </Col>
          )}
        </Row>
      )}
    </div>
  );
});
export default Profiles;
